/** @format */

const Letters04 = [
	'34744.jpg',
	'35197.jpg',
	'35792.jpg',
	'35793.jpg',
	'40161.jpg',
	'40173.jpg',
	'40211.jpg',
	'40215.jpg',
	'40216.jpg',
	'40219.jpg',
	'40234.jpg',
	'40236.jpg',
	'40246.jpg',
	'40267.jpg',
	'40297.jpg',
	'40303.jpg',
];
export default Letters04;
