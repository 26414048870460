/** @format */

import React from 'react';
import { observer, inject } from 'mobx-react';
import { Dropdown, Grid, Divider, Search } from 'semantic-ui-react';
import SearchBar from './SearchBar';

const AwButton = inject(
	'awStore',
	'slideStore'
)(
	observer(function AwButton(props) {
		return (
			<div>
				<Grid>
					<Grid.Column width={6}>
						<Dropdown
							text='Step1: Select a level'
							className='button icon'
							floating
							fluid
							options={props.awStore.levelOptions}
							onChange={(e, v) => {
								if (v.value !== props.awStore.level)
									props.awStore.setLevel(v.value);
							}}
							labeled
							icon='folder open'
							disabled={!props.slideStore.user}
						/>
						<div style={{ paddingTop: '10px' }}>
							<Divider horizontal inverted>
								Level {props.awStore.level}
							</Divider>
						</div>
					</Grid.Column>
					<Grid.Column width={6}>
						<Dropdown
							text='Step2: Select a dialogue'
							className='button icon'
							floating
							fluid
							options={props.awStore.selectOptions}
							onChange={(e, v) => {
								if (v.value.key !== props.awStore.book.key)
									props.awStore.setBook(v.value);
							}}
							labeled
							icon='book'
							disabled={!props.slideStore.user}
						/>
						<div style={{ paddingTop: '10px' }}>
							<Divider horizontal inverted>
								{' '}
								{props.awStore.book.name}
							</Divider>
						</div>
					</Grid.Column>
					{/* <Grid.Column width={4}>
						<SearchBar
							logged={!!props.slideStore.user}
							onSelect={this.onSelectSearchResult}
							options={collectSlideUtils.getSearchOptions()}
						/>
					</Grid.Column> */}
				</Grid>
			</div>
		);
	})
);

export default AwButton;
