/** @format */
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Pagination, Segment } from 'semantic-ui-react';

const LyricDisplay = inject('musicStore')(
	observer(
		class LyricDisplay extends Component {
			constructor(props) {
				super(props);
				this.state = { activePage: 1 };
			}

			render() {
				return (
					<div>
						<Segment secondary inverted color='black'>
							<div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
								<div class='header'>
									{this.props.musicStore.lyric[this.state.activePage - 1]}
								</div>
							</div>
						</Segment>
						<Pagination
							color='black'
							defaultActivePage={1}
							activePage={this.state.activePage}
							firstItem={null}
							lastItem={null}
							secondary
							totalPages={this.props.musicStore.lyricLength}
							onPageChange={(e, { activePage }) =>
								this.setState({ activePage: activePage })
							}
							style={{ fontSize: '0.5em' }}
							inverted
							pointing
						/>
					</div>
				);
			}
		}
	)
);

export default LyricDisplay;
