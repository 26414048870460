/** @format */

import { useOktaAuth } from '@okta/okta-react';
import React from 'react';
import { Container, Menu, Image, Dropdown } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import {
	SLIDE,
	CROSSWORD,
	WORDCARD,
	DIALOGUE,
	MUSIC,
	MATCH,
	MAZE,
	SCRAMBLE,
	DIFFERENCE,
	CROSSWORDIMAGE,
	COUNTING,
	COLOR_THEME,
	CURRENT_THEME,
	BOOK,
	CARTOON,
	SCENE,
	ACORN_WORLD,
} from '../constants/constants';

const Navbar = inject('slideStore')(
	observer((props) => {
		const { authState, authService } = useOktaAuth();

		const login = async () => authService.login('/');
		const logout = async () => authService.logout('/');

		return (
			<div>
				<Menu
					stackable
					size='tiny'
					style={{ backgroundColor: COLOR_THEME[CURRENT_THEME]['menu'] }}
					pointing
					inverted
					secondary
					widths={9}>
					<Container>
						<Menu.Item>
							<div style={{ paddingLeft: '-20px' }}>
								<Image
									className='img-responsive'
									src={require('../assets/images/logoGold.png').default}
									alt='cannot find logo'
									as='a'
									href='https://www.acornus.net/'
								/>
							</div>
						</Menu.Item>
						<Menu.Item
							id='AcornWorld'
							onClick={() => props.slideStore.setContent(ACORN_WORLD)}>
							<div>Dialogues</div>
						</Menu.Item>
						<Menu.Item
							id='ClassSlide'
							onClick={() => props.slideStore.setContent(SLIDE)}>
							<div>Slides</div>
						</Menu.Item>
						<Menu.Item
							id='Books'
							onClick={() => props.slideStore.setContent(BOOK)}>
							<div>Books</div>
						</Menu.Item>
						<Menu.Item
							id='cartoon'
							onClick={() => props.slideStore.setContent(CARTOON)}>
							<div>Cartoon</div>
						</Menu.Item>
						<Menu.Item
							id='scene'
							onClick={() => props.slideStore.setContent(SCENE)}>
							<div> Scenes</div>
						</Menu.Item>
						<Dropdown item className='menu-item' text='Videos'>
							<Dropdown.Menu>
								<Dropdown.Item
									id='Video'
									onClick={() => props.slideStore.setContent(DIALOGUE)}>
									<div>Videos</div>
								</Dropdown.Item>
								<Dropdown.Item
									id='Music'
									onClick={() => props.slideStore.setContent(MUSIC)}>
									<div>Music</div>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<Dropdown item className='menu-item' text='Games'>
							<Dropdown.Menu>
								<Dropdown.Item
									id='Match'
									onClick={() => props.slideStore.setContent(MATCH)}>
									Matching Game
								</Dropdown.Item>
								<Dropdown.Item
									id='WordCard'
									onClick={() => props.slideStore.setContent(WORDCARD)}>
									Flashcard
								</Dropdown.Item>
								<Dropdown.Item
									id='Scramble'
									onClick={() => props.slideStore.setContent(SCRAMBLE)}>
									Scramble
								</Dropdown.Item>
								<Dropdown.Item
									id='Maze'
									onClick={() => props.slideStore.setContent(MAZE)}>
									Maze
								</Dropdown.Item>
								<Dropdown.Item
									id='Difference'
									onClick={() => props.slideStore.setContent(DIFFERENCE)}>
									Find Differences
								</Dropdown.Item>
								<Dropdown.Item
									id='Maze'
									onClick={() => props.slideStore.setContent(CROSSWORDIMAGE)}>
									Crossword-Image
								</Dropdown.Item>
								<Dropdown.Item
									id='Maze'
									onClick={() => props.slideStore.setContent(COUNTING)}>
									Counting Game
								</Dropdown.Item>
								<Dropdown.Item
									id='Crossword'
									onClick={() => props.slideStore.setContent(CROSSWORD)}>
									Crossword-Words
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						{authState.isAuthenticated && (
							<Menu.Item id='logout-button' onClick={logout} position='right'>
								<div>Logout</div>
							</Menu.Item>
						)}
						{!authState.isPending && !authState.isAuthenticated && (
							<Menu.Item onClick={login} position='right'>
								<div>Login</div>
							</Menu.Item>
						)}
					</Container>
				</Menu>
			</div>
		);
	})
);

export default Navbar;
