/** @format */

const Letters06 = [
	'35203.jpg',
	'35759.jpg',
	'35850.jpg',
	'35943.jpg',
	'40164.jpg',
	'40165.jpg',
	'40166.jpg',
	'40178.jpg',
	'40180.jpg',
	'40185.jpg',
	'40194.jpg',
	'40196.jpg',
	'40198.jpg',
	'40200.jpg',
	'40202.jpg',
	'40213.jpg',
	'40250.jpg',
	'40253.jpg',
	'40256.jpg',
	'40261.jpg',
	'40271.jpg',
	'40286.jpg',
	'40287.jpg',
	'40300.jpg',
	'40302.jpg',
	'40304.jpg',
	'40308.jpg',
	'40312.jpg',
	'40321.jpg',
	'40326.jpg',
];
export default Letters06;
