/** @format */
export const MAZE_ARRAY = [
	'Animals',
	'DailyLife',
	'Dinosaur',
	'Fantasy',
	'Food',
	'Forest',
	'Space',
];
export const MAZE_DISPLAY_MAP = new Map([
	['Animals', 'Animals'],
	['DailyLife', 'Daily Life'],
	['Dinosaur', 'Dinosaurs'],
	['Fantasy', 'Fantasy'],
	['Food', 'Food'],
	['Forest', 'Forest'],
	['Space', 'Space'],
]);
export const MAZE_PAGE_NUM = new Map([
	['Animals', 21],
	['DailyLife', 2],
	['Dinosaur', 5],
	['Fantasy', 15],
	['Food', 2],
	['Forest', 3],
	['Space', 10],
]);
export const MAZE_DIR = 'games/maze';

export const CROSSWORD_ARRAY = [
	'Animals',
	'BodyPart',
	'Clothing',
	'DailyThing',
	'Dinosaur',
	'Fantasy',
	'Forest',
	'SeaLife',
	'Shape',
	'Space',
	'Toy',
	'Transportation',
	'VeggieFruit',
];
export const CROSSWORD_DISPLAY_MAP = new Map([
	['Animals', 'Animals'],
	['BodyPart', 'Body Parts'],
	['Clothing', 'Clothing'],
	['DailyThing', 'Daily Things'],
	['Dinosaur', 'Dinosaur'],
	['Fantasy', 'Fantasy'],
	['Forest', 'Forest'],
	['SeaLife', 'SeaLife'],
	['Shape', 'Shapes'],
	['Space', 'Space'],
	['Toy', 'Toys'],
	['Transportation', 'Transportation'],
	['VeggieFruit', 'Vegetable and Fruits'],
]);
export const CROSSWORD_PAGE_NUM = new Map([
	['Animals', 41],
	['BodyPart', 3],
	['Clothing', 2],
	['DailyThing', 8],
	['Dinosaur', 1],
	['Fantasy', 4],
	['Forest', 3],
	['SeaLife', 9],
	['Shape', 1],
	['Space', 5],
	['Toy', 3],
	['Transportation', 5],
	['VeggieFruit', 10],
]);
export const CROSSWORD_DIR = 'games/crossword';

export const COUNTING_ARRAY = [
	'Animals',
	'Clothing',
	'DailyThing',
	'Fantasy',
	'Food',
	'Forest',
	'SeaLife',
	'Space',
	'Toy',
	'Transportation',
];
export const COUNTING_DISPLAY_MAP = new Map([
	['Animals', 'Animals'],
	['Clothing', 'Clothing'],
	['DailyThing', 'Daily Things'],
	['Fantasy', 'Fantasy'],
	['Food', 'Food'],
	['Forest', 'Forest'],
	['SeaLife', 'SeaLife'],
	['Space', 'Space'],
	['Toy', 'Toys'],
	['Transportation', 'Transportation'],
]);
export const COUNTING_PAGE_NUM = new Map([
	['Animals', 7],
	['Clothing', 3],
	['DailyThing', 2],
	['Fantasy', 3],
	['Food', 14],
	['Forest', 3],
	['SeaLife', 6],
	['Space', 3],
	['Toy', 3],
	['Transportation', 2],
]);
export const COUNTING_DIR = 'games/counting';

export const DIFFERENCE_ARRAY = [
	'Animals',
	'DailyRoutine',
	'Fantasy',
	'People',
	'Robot',
	'VeggieFruit',
	'Transportation',
];
export const DIFFERENCE_DISPLAY_MAP = new Map([
	['Animals', 'Animals'],
	['DailyRoutine', 'Daily Routine'],
	['Fantasy', 'Fantasy'],
	['People', 'People'],
	['Robot', 'Robots'],
	['VeggieFruit', 'Vegetable and Fruit'],
	['Transportation', 'Transportation'],
]);
export const DIFFERENCE_PAGE_NUM = new Map([
	['Animals', 32],
	['DailyRoutine', 4],
	['Fantasy', 27],
	['People', 21],
	['Robot', 4],
	['VeggieFruit', 5],
	['Transportation', 7],
]);
export const DIFFERENCE_DIR = 'games/difference';

export const DEFAULT_GAME = 'Animals';

export const SCRAMBLE_LIST = {
	'4 Letters': 'Letters04',
	'5 Letters': 'Letters05',
	'6 Letters': 'Letters06',
	'7 Letters': 'Letters07',
	'8 Letters': 'Letters08',
	'9 Letters': 'Letters09',
};
