/** @format */

const JingleBells = [
	`Dashing through the snow, on a one horse open sleigh
O'er the fields we go, laughing all the way
Bells on bob tail ring, making spirits bright
What fun it is to laugh and sing a sleighing song tonight
Oh, jingle bells, jingle bells, Jingle all the way
Oh, what fun it is to ride in a one horse open sleigh`,
];

export default { JingleBells };
