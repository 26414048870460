/** @format */

import dialogueStore from '../stores/dialogueStore';
import collectSlideUtils from './collectSlideUtils';
import _ from 'lodash';
import {
	DIALOGUE_ARRAY,
	DIALOGUE_GROUP_MAP,
	DIALOGUE_DISPLAY_MAP,
	DEFAULT_DIALOGUE,
} from '../constants/dialogueConfigs';

const initializeData = (isLoggedIn) => {
	dialogueStore.setLevel(DIALOGUE_GROUP_MAP.get(DEFAULT_DIALOGUE));
	dialogueStore.setBook(DEFAULT_DIALOGUE);
	dialogueStore.setDefaultSelectOptions(
		collectSlideUtils.getDropdownOption(
			[DEFAULT_DIALOGUE],
			DIALOGUE_DISPLAY_MAP
		)[0]
	);
	dialogueStore.setDefaultLevelOptions(
		getLevelOption([DEFAULT_DIALOGUE], DIALOGUE_GROUP_MAP)[0]
	);

	let levelOptions, dropdownOptions;
	if (isLoggedIn) {
		levelOptions = getLevelOption(DIALOGUE_ARRAY, DIALOGUE_GROUP_MAP);
		const slideArrayByLevel = DIALOGUE_ARRAY.filter(
			(book) =>
				DIALOGUE_GROUP_MAP.get(book) === dialogueStore.defaultLevelOption.value
		);
		dropdownOptions = collectSlideUtils.getDropdownOption(
			slideArrayByLevel,
			DIALOGUE_DISPLAY_MAP
		);
	} else {
		levelOptions = getLevelOption([DEFAULT_DIALOGUE], DIALOGUE_GROUP_MAP);
		dropdownOptions = collectSlideUtils.getDropdownOption(
			[DEFAULT_DIALOGUE],
			DIALOGUE_DISPLAY_MAP
		);
	}

	dialogueStore.setLevelOptions(levelOptions);
	dialogueStore.setSelectOptions(dropdownOptions);
};

const getLevelOption = (bookArray, groupMap) => {
	const levelArray = _.chain(bookArray)
		.map((b) => groupMap.get(b))
		.uniq()
		.value();
	levelArray.sort();
	const options = [];
	for (let i = 0; i < levelArray.length; i++) {
		options.push({
			key: Math.floor(Math.random() * 100000000),
			text: levelArray[i],
			value: levelArray[i],
		});
	}
	return options;
};

export default {
	initializeData,
};
