/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './utils/polyfills';
import App from './App';
import './css/index.css';
import './css/App.css';
import './css/carousel.css';
import './css/snow.css';
import './css/neon.css';
import './css/lightrope.css';
import './css/game.css';
import registerServiceWorker from './utils/registerServiceWorker';
import slideStore from './stores/slideStore';
import awStore from './stores/awStore';
import galleryStore from './stores/galleryStore';
import dialogueStore from './stores/dialogueStore';
import musicStore from './stores/musicStore';
import wordCardStore from './stores/wordCardStore';
import { Provider } from 'mobx-react';

const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');

// the setTimeout simulates the time it takes react to load, and is not part of the solution
setTimeout(
	() =>
		// the show/hide functions are passed as props
		ReactDOM.render(
			<Provider
				{...{
					slideStore,
					galleryStore,
					dialogueStore,
					musicStore,
					wordCardStore,
					awStore
				}}>
				<App hideLoader={hideLoader} showLoader={showLoader} />
			</Provider>,
			document.getElementById('root')
		),
	1000
);

registerServiceWorker();
