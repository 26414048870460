/** @format */

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dropdown, Grid, Divider } from 'semantic-ui-react';
import collectSlideUtils from '../utils/collectSlideUtils';
import {
	DIALOGUE_DISPLAY_MAP,
	DIALOGUE_ARRAY,
	DIALOGUE_GROUP_MAP,
} from '../constants/dialogueConfigs';

const DialogueButton = inject(
	'slideStore',
	'dialogueStore'
)(
	observer(
		class SlideButton extends Component {
			chooseBook = (v) => {
				const newBook = this.props.dialogueStore.selectOptions[v.value.i];
				this.props.dialogueStore.setBook(newBook.book);
			};

			chooseLevel = (v) => {
				this.props.dialogueStore.setLevel(v.value);
				const newHeaderArray = collectSlideUtils.findHeaderByLevel(
					v.value,
					DIALOGUE_ARRAY,
					DIALOGUE_GROUP_MAP
				);
				const selectOptions = collectSlideUtils.getDropdownOption(
					newHeaderArray,
					DIALOGUE_DISPLAY_MAP
				);
				this.props.dialogueStore.setSelectOptions(selectOptions);
			};

			render() {
				return (
					<div>
						<Grid columns={2}>
							<Grid.Column>
								<Dropdown
									text='Step1: Select a Group'
									className='button icon'
									floating
									fluid
									defaultValue={this.props.dialogueStore.defaultLevelOption}
									options={this.props.dialogueStore.levelOptions}
									onChange={(e, v) => this.chooseLevel(v)}
									labeled
									icon='folder open'
									disabled={!this.props.slideStore.user}
								/>
								<div style={{ paddingTop: '10px' }}>
									<Divider horizontal inverted>
										{this.props.dialogueStore.level}
									</Divider>
								</div>
							</Grid.Column>
							<Grid.Column>
								<Dropdown
									text='Step2: Select a Dialogue'
									className='button icon'
									floating
									fluid
									defaultValue={this.props.dialogueStore.defaultSelectOption}
									options={this.props.dialogueStore.selectOptions}
									onChange={(e, v) => this.chooseBook(v)}
									labeled
									icon='comments'
									disabled={!this.props.slideStore.user}
								/>
								<div style={{ paddingTop: '10px' }}>
									<Divider horizontal inverted>
										{' '}
										{DIALOGUE_DISPLAY_MAP.get(this.props.dialogueStore.book)}
									</Divider>
								</div>
							</Grid.Column>
						</Grid>
					</div>
				);
			}
		}
	)
);

export default DialogueButton;
