/** @format */

import wordCardStore from '../stores/wordCardStore';
import collectSlideUtils from './collectSlideUtils';
import _ from 'lodash';
import {
	WORDCARD_ARRAY,
	WORDCARD_GROUP_MAP,
	WORDCARD_DISPLAY_MAP,
	DEFAULT_WORDCARD,
	WORDCARD_FILES,
} from '../constants/wordCardConfigs';

const initializeData = (isLoggedIn) => {
	wordCardStore.setLevel(WORDCARD_GROUP_MAP.get(DEFAULT_WORDCARD));
	wordCardStore.setBook(DEFAULT_WORDCARD);
	wordCardStore.setDefaultSelectOptions(
		collectSlideUtils.getDropdownOption(
			[DEFAULT_WORDCARD],
			WORDCARD_DISPLAY_MAP
		)[0]
	);
	wordCardStore.setDefaultLevelOptions(
		getLevelOption([DEFAULT_WORDCARD], WORDCARD_GROUP_MAP)[0]
	);

	let levelOptions, dropdownOptions;
	if (isLoggedIn) {
		levelOptions = getLevelOption(WORDCARD_ARRAY, WORDCARD_GROUP_MAP);
		const slideArrayByLevel = WORDCARD_ARRAY.filter(
			(book) =>
				WORDCARD_GROUP_MAP.get(book) === wordCardStore.defaultLevelOption.value
		);
		dropdownOptions = collectSlideUtils.getDropdownOption(
			slideArrayByLevel,
			WORDCARD_DISPLAY_MAP
		);
	} else {
		levelOptions = getLevelOption([DEFAULT_WORDCARD], WORDCARD_GROUP_MAP);
		dropdownOptions = collectSlideUtils.getDropdownOption(
			[DEFAULT_WORDCARD],
			WORDCARD_DISPLAY_MAP
		);
	}

	wordCardStore.setLevelOptions(levelOptions);
	wordCardStore.setSelectOptions(dropdownOptions);
	wordCardStore.setImageNameList(createWordCardComponent(DEFAULT_WORDCARD));
};

const getLevelOption = (bookArray, groupMap) => {
	const levelArray = _.chain(bookArray)
		.map((b) => groupMap.get(b))
		.uniq()
		.value();
	levelArray.sort();
	const options = [];
	for (let i = 0; i < levelArray.length; i++) {
		options.push({
			key: Math.floor(Math.random() * 100000000),
			text: levelArray[i],
			value: levelArray[i],
		});
	}
	return options;
};

const createWordCardComponent = (topic) => {
	let imageNameList = [];
	WORDCARD_FILES[topic].forEach((fileName) => {
		imageNameList.push({
			front: fileName,
			back: fileName.split('-')[1].split('.')[0],
		});
	});
	return imageNameList;
};

export default {
	initializeData,
	createWordCardComponent,
};
