/** @format */

import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

class MessageDismissibleBlock extends Component {
	constructor(props) {
		super(props);
		this.state = { visible: this.props.visible };
		if (this.props.autoDis) {
			setTimeout(() => {
				this.setState({ visible: false });
			}, 5000);
		}
	}

	handleDismiss = () => {
		this.setState({ visible: false });
	};

	render() {
		if (this.state.visible) {
			return (
				<Message
					onDismiss={this.handleDismiss}
					header={this.props.message}
					size={this.props.size}
					color={this.props.color}
				/>
			);
		}
		return <div></div>;
	}
}

export default MessageDismissibleBlock;
