/** @format */

// slides constants
export const SLIDE_DISPLAY_MAP = new Map([
	['WordGames', 'Word Games'],
	['BodyGame', 'Body Game'],
	['Colors', 'Colors'],
	['Fall', 'Fall'],
	['Letters1', 'Letters A-I'],
	['Letters2', 'Letters H-R'],
	['Letters3', 'Letters S-Z'],
	['AnimalGame', 'Animal Games'],
	['Emotions', 'Emotions'],
	['Spiderman', 'Spiderman 01'],
	['Spiderman2', 'Spiderman 02'],
	['Weather', 'Weather'],
	['WordWork', 'Word Work'],
	['DailyRoutine', 'Daily Routine'],
	['Animals', 'Animals 01'],
	['Animals2', 'Animals 02'],
	['WhereAreYouFrom', 'Where Are You From?'],
	['IFeelSick', 'I Feel Sick!'],
	['FoodStore', 'Food Store'],
	['Cars', 'Cars'],
	['Winter', 'Winter'],
	['Christmas', 'Christmas'],
	['Time', 'Telling Time'],
	['Economics_01', 'Economics 01'],
	['SolarSystem', 'Solar System'],
	['SeaLife', 'Sea Life'],
	['Numbers', 'Numbers 01'],
	['Numbers2', 'Numbers 02'],
	['ColorCars', 'Colors with Cars'],
	['FrozenABC', 'Frozen ABC 01'],
	['FrozenABC2', 'Frozen ABC 02'],
	['Seasons', 'Seasons'],
	['Days', 'Days of the Week'],
	['Months', 'Months of the Year'],
	['AMNH', 'American Museum of Natural History'],
	['NewYearResolution', "New Year's Resolutions"],
	['Biology_01', 'Biology 01 Communicable Disease'],
	['Dinosaurs', 'Dinosaurs'],
	['DreamJobs1', 'Dream Job 01'],
	['DreamJobs2', 'Dream Job 02'],
	['TheNewYear', 'The New Year'],
	['Travel', 'Travel'],
	['Geography01', 'Geography 01 Earth Structure'],
	['Physics01', 'Physics 01 Life Cycle Of Stars'],
	['HappyBirthday', 'Happy Birthday!'],
	['WeatherReporting', 'Weather Reporting'],
	['Zoo', 'Zoo'],
	['SpringFestival', 'Spring Festival'],
	['ExpressingFeelings', 'Expressing Feelings'],
	['Music', 'Music'],
	['MusicalIntruments', 'Musical Intruments'],
	['Boxing', 'Boxing'],
	['Galaxy', 'Our Galaxy'],
	['ThreePigs', 'The Three Little Pigs'],
	['GuessJobs1', 'Guess My Jobs 01'],
	['GuessJobs2', 'Guess My Jobs 02'],
	['PhysicalDescriptions', 'Physical Descriptions'],
	['DescribingPeople', 'Describing People'],
	['MyHouse', 'My House'],
	['BackToSchool', 'Back to School'],
	['JungleLife', 'Jungle Life'],
	['FastFood', 'Fast Food'],
	['ThePiano', 'The Piano'],
	['Hockey', 'Hockey'],
	['Robot', 'Robot and Battery'],
	['WinterClothes', 'Winter Clothes'],
	['TreasureHunting', 'Treasure Hunting'],
	['EasterDay', 'Easter Day'],
	['FireFighting', 'Fire Fighting'],
	['Circus', 'Day At The Circus'],
	['TheWorldMexico', 'The World - Mexico'],
	['TheWorldJapan', 'The World - Japan'],
	['TheWorldEngland', 'The World - England'],
	['TheWorldEgypt', 'The World - Egypt'],
	['TheWorldBrazil', 'The World - Brazil'],
	['TheWorldRussia', 'The World - Russia'],
	['AnimalsBlue', 'Animals - Blue Color'],
	['AnimalsGreen', 'Animals - Green Color'],
	['AnimalsOrange', 'Animals - Orange Color'],
	['AnimalsYellow', 'Animals - Yellow Color'],
	['AnimalsWhite', 'Animals - White Color'],
	['AnimalsRed', 'Animals - Red Color'],
	['AstronomyEarth', 'Astronomy - Earth'],
	['AstronomyJupiter', 'Astronomy - Jupiter'],
	['AstronomyMercury', 'Astronomy - Mercury'],
	['AstronomySaturn', 'Astronomy - Saturn'],
	['AstronomyVenus', 'Astronomy - Venus'],
	['AstronomyMars', 'Astronomy - Mars'],
	['NASA', 'NASA'],
	['AnimalMondey01', 'Monkey - Introduction'],
	['AnimalMondey02', 'Monkey - Knowledge'],
	['TransportationSpaceships', 'Transportation - Spaceships'],
	['TransportationBoats', 'Transportation - Boats'],
	['TransportationPlanes', 'Transportation - Planes'],
	['TransportationTrains', 'Transportation - Trains'],
	['TransportationCars', 'Transportation - Cars'],
	['Shapes', 'Shapes'],
	['Opposites01', 'Opposites 01'],
	['Opposites02', 'Opposites 02'],
	['Opposites03', 'Opposites 03'],
	['Spring21', 'Hello Spring'],
	['EconomicsGoodsService', 'Economics - Goods And Service'],
	['EconomicsNeedsWants', 'Economics - Needs And Wants'],
	['EconomicsSpendingSaving', 'Economics - Spending And Saving'],
	['EconomicsSupplyDemand', 'Economics - Supply And Demand'],
	['Tennis', 'Tennis'],
	['Guitars', 'Guitars'],
	['Astronaut', 'To be an Astronaut'],
	['AnimalFeaturesClaws', 'Animal Features - Claws'],
	['AnimalFeaturesHorns', 'Animal Features - Horns'],
	['AnimalFeaturesSpots', 'Animal Features - Spots'],
	['AnimalFeaturesTail', 'Animal Features - Tail'],
	['MotherDay', "Mother's Day!"],
	['Basketball', 'Basketball'],
	['TheElements ', 'The Elements'],
	['AtomsAndCompounds ', 'Atoms And Compounds'],
	['ChildrenDay', "Children's Day!"],
	['CompoundWords', 'Compound Words'],
]);

export const SLIDE_LEVEL_MAP = new Map([
	['WordGames', '04'],
	['BodyGame', '03'],
	['Colors', '03'],
	['Fall', '07'],
	['Letters1', '03'],
	['Letters2', '03'],
	['Letters3', '03'],
	['AnimalGame', '08'],
	['Emotions', '04'],
	['Spiderman', '05'],
	['Spiderman2', '05'],
	['Weather', '06'],
	['WordWork', '04'],
	['DailyRoutine', '05'],
	['Animals', '05'],
	['Animals2', '05'],
	['WhereAreYouFrom', '07'],
	['IFeelSick', '07'],
	['FoodStore', '07'],
	['Cars', '07'],
	['Winter', '08'],
	['Christmas', '09'],
	['Time', '05'],
	['Economics_01', '15'],
	['SolarSystem', '10'],
	['SeaLife', '07'],
	['Numbers', '03'],
	['Numbers2', '03'],
	['ColorCars', '04'],
	['FrozenABC', '03'],
	['FrozenABC2', '03'],
	['Seasons', '08'],
	['Days', '05'],
	['Months', '06'],
	['AMNH', '11'],
	['NewYearResolution', '09'],
	['Biology_01', '12'],
	['Dinosaurs', '06'],
	['DreamJobs1', '06'],
	['DreamJobs2', '06'],
	['TheNewYear', '09'],
	['Travel', '07'],
	['Geography01', '13'],
	['Physics01', '14'],
	['HappyBirthday', '09'],
	['WeatherReporting', '08'],
	['Zoo', '06'],
	['SpringFestival', '10'],
	['ExpressingFeelings', '07'],
	['Music', '08'],
	['MusicalIntruments', '09'],
	['Boxing', '13'],
	['Galaxy', '08'],
	['ThreePigs', '06'],
	['GuessJobs1', '10'],
	['GuessJobs2', '10'],
	['PhysicalDescriptions', '08'],
	['DescribingPeople', '09'],
	['MyHouse', '06'],
	['BackToSchool', '05'],
	['JungleLife', '07'],
	['FastFood', '06'],
	['ThePiano', '11'],
	['Hockey', '10'],
	['Robot', '07'],
	['WinterClothes', '05'],
	['TreasureHunting', '06'],
	['EasterDay', '06'],
	['FireFighting', '10'],
	['Circus', '07'],
	['TheWorldMexico', '12'],
	['TheWorldJapan', '12'],
	['TheWorldEngland', '12'],
	['TheWorldEgypt', '12'],
	['TheWorldBrazil', '12'],
	['TheWorldRussia', '12'],
	['AnimalsBlue', '10'],
	['AnimalsGreen', '10'],
	['AnimalsOrange', '10'],
	['AnimalsYellow', '10'],
	['AnimalsWhite', '10'],
	['AnimalsRed', '10'],
	['AstronomyEarth', '11'],
	['AstronomyJupiter', '11'],
	['AstronomyMercury', '11'],
	['AstronomySaturn', '11'],
	['AstronomyVenus', '11'],
	['AstronomyMars', '11'],
	['NASA', '07'],
	['AnimalMondey01', '08'],
	['AnimalMondey02', '10'],
	['TransportationSpaceships', '08'],
	['TransportationBoats', '08'],
	['TransportationPlanes', '08'],
	['TransportationTrains', '08'],
	['TransportationCars', '08'],
	['Shapes', '05'],
	['Opposites01', '05'],
	['Opposites02', '05'],
	['Opposites03', '05'],
	['Spring21', '06'],
	['EconomicsGoodsService', '12'],
	['EconomicsNeedsWants', '12'],
	['EconomicsSpendingSaving', '12'],
	['EconomicsSupplyDemand', '12'],
	['Tennis', '12'],
	['Guitars', '12'],
	['Astronaut', '12'],
	['AnimalFeaturesClaws', '08'],
	['AnimalFeaturesHorns', '08'],
	['AnimalFeaturesSpots', '08'],
	['AnimalFeaturesTail', '08'],
	['MotherDay', '06'],
	['Basketball', '06'],
	['TheElements ', '14'],
	['AtomsAndCompounds ', '14'],
	['ChildrenDay', '09'],
	['CompoundWords', '05'],
]);

export const SLIDE_ARRAY = [
	'WordGames',
	'BodyGame',
	'Colors',
	'Fall',
	'Letters1',
	'Letters2',
	'Letters3',
	'AnimalGame',
	'Emotions',
	'Spiderman',
	'Spiderman2',
	'Weather',
	'WordWork',
	'DailyRoutine',
	'Animals',
	'Animals2',
	'WhereAreYouFrom',
	'IFeelSick',
	'FoodStore',
	'Cars',
	'Winter',
	'Christmas',
	'Time',
	'Economics_01',
	'SolarSystem',
	'SeaLife',
	'Numbers',
	'Numbers2',
	'ColorCars',
	'FrozenABC',
	'FrozenABC2',
	'Seasons',
	'Days',
	'Months',
	'AMNH',
	'NewYearResolution',
	'Biology_01',
	'Dinosaurs',
	'DreamJobs1',
	'DreamJobs2',
	'TheNewYear',
	'Travel',
	'Geography01',
	'Physics01',
	'HappyBirthday',
	'WeatherReporting',
	'Zoo',
	'SpringFestival',
	'ExpressingFeelings',
	'Music',
	'MusicalIntruments',
	'Boxing',
	'Galaxy',
	'ThreePigs',
	'GuessJobs1',
	'GuessJobs2',
	'PhysicalDescriptions',
	'DescribingPeople',
	'MyHouse',
	'BackToSchool',
	'JungleLife',
	'FastFood',
	'ThePiano',
	'Hockey',
	'Robot',
	'WinterClothes',
	'TreasureHunting',
	'EasterDay',
	'FireFighting',
	'Circus',
	'TheWorldMexico',
	'TheWorldJapan',
	'TheWorldEngland',
	'TheWorldEgypt',
	'TheWorldBrazil',
	'TheWorldRussia',
	'AnimalsBlue',
	'AnimalsGreen',
	'AnimalsOrange',
	'AnimalsYellow',
	'AnimalsWhite',
	'AnimalsRed',
	'AstronomyEarth',
	'AstronomyJupiter',
	'AstronomyMercury',
	'AstronomySaturn',
	'AstronomyVenus',
	'AstronomyMars',
	'NASA',
	'AnimalMondey01',
	'AnimalMondey02',
	'TransportationSpaceships',
	'TransportationBoats',
	'TransportationPlanes',
	'TransportationTrains',
	'TransportationCars',
	'Shapes',
	'Opposites01',
	'Opposites02',
	'Opposites03',
	'Spring21',
	'EconomicsGoodsService',
	'EconomicsNeedsWants',
	'EconomicsSpendingSaving',
	'EconomicsSupplyDemand',
	'Tennis',
	'Guitars',
	'Astronaut',
	'AnimalFeaturesClaws',
	'AnimalFeaturesHorns',
	'AnimalFeaturesSpots',
	'AnimalFeaturesTail',
	'MotherDay',
	'Basketball',
	'TheElements ',
	'AtomsAndCompounds ',
	'ChildrenDay',
	'CompoundWords',
];

export const SLIDE_PAGE_NUM = new Map([
	['WordGames', 23],
	['BodyGame', 18],
	['Colors', 16],
	['Fall', 17],
	['Letters1', 25],
	['Letters2', 24],
	['Letters3', 21],
	['AnimalGame', 21],
	['Emotions', 16],
	['Spiderman', 17],
	['Spiderman2', 23],
	['Weather', 18],
	['WordWork', 19],
	['DailyRoutine', 20],
	['Animals', 27],
	['Animals2', 25],
	['WhereAreYouFrom', 23],
	['IFeelSick', 17],
	['FoodStore', 21],
	['Cars', 31],
	['Winter', 28],
	['Christmas', 42],
	['Time', 19],
	['Economics_01', 9],
	['SolarSystem', 19],
	['SeaLife', 40],
	['Numbers', 21],
	['Numbers2', 21],
	['ColorCars', 14],
	['FrozenABC', 15],
	['FrozenABC2', 13],
	['Seasons', 23],
	['Days', 25],
	['Months', 27],
	['AMNH', 18],
	['NewYearResolution', 21],
	['Biology_01', 15],
	['Dinosaurs', 22],
	['DreamJobs1', 22],
	['DreamJobs2', 22],
	['TheNewYear', 22],
	['Travel', 15],
	['Geography01', 11],
	['Physics01', 12],
	['HappyBirthday', 23],
	['WeatherReporting', 13],
	['Zoo', 17],
	['SpringFestival', 23],
	['ExpressingFeelings', 13],
	['Music', 20],
	['MusicalIntruments', 42],
	['Boxing', 10],
	['Galaxy', 22],
	['ThreePigs', 13],
	['GuessJobs1', 25],
	['GuessJobs2', 23],
	['PhysicalDescriptions', 18],
	['DescribingPeople', 16],
	['MyHouse', 13],
	['BackToSchool', 16],
	['JungleLife', 15],
	['FastFood', 14],
	['ThePiano', 19],
	['Hockey', 15],
	['Robot', 19],
	['WinterClothes', 26],
	['TreasureHunting', 13],
	['EasterDay', 20],
	['FireFighting', 12],
	['Circus', 12],
	['TheWorldMexico', 10],
	['TheWorldJapan', 11],
	['TheWorldEngland', 10],
	['TheWorldEgypt', 9],
	['TheWorldBrazil', 10],
	['TheWorldRussia', 11],
	['AnimalsBlue', 10],
	['AnimalsGreen', 10],
	['AnimalsOrange', 10],
	['AnimalsYellow', 10],
	['AnimalsWhite', 10],
	['AnimalsRed', 10],
	['AstronomyEarth', 11],
	['AstronomyJupiter', 11],
	['AstronomyMercury', 11],
	['AstronomySaturn', 10],
	['AstronomyVenus', 11],
	['AstronomyMars', 11],
	['NASA', 11],
	['AnimalMondey01', 9],
	['AnimalMondey02', 10],
	['TransportationSpaceships', 11],
	['TransportationBoats', 11],
	['TransportationPlanes', 11],
	['TransportationTrains', 11],
	['TransportationCars', 11],
	['Shapes', 31],
	['Opposites01', 19],
	['Opposites02', 19],
	['Opposites03', 19],
	['Spring21', 20],
	['EconomicsGoodsService', 9],
	['EconomicsNeedsWants', 10],
	['EconomicsSpendingSaving', 10],
	['EconomicsSupplyDemand', 11],
	['Tennis', 12],
	['Guitars', 8],
	['Astronaut', 13],
	['AnimalFeaturesClaws', 11],
	['AnimalFeaturesHorns', 9],
	['AnimalFeaturesSpots', 11],
	['AnimalFeaturesTail', 11],
	['MotherDay', 19],
	['Basketball', 12],
	['TheElements ', 22],
	['AtomsAndCompounds ', 27],
	['ChildrenDay', 17],
	['CompoundWords', 30],
]);

export const SLIDE_VIDEO_MAP = new Map([
	['BodyGame', '-6-Q1yTEx54'],
	['Colors', 'RVS9C29LJgc'],
	['Spiderman', 'JqNjeNeJzuA'],
	['Spiderman2', 'JqNjeNeJzuA'],
	['Weather', 'P9abGg_gF1s'],
	['Animals', 'zXEq-QO3xTg'],
	['Animals2', 'NNELmTbw9yM'],
	['WhereAreYouFrom', 'l6A2EFkjXq4'],
	['IFeelSick', '5xZYFPJ0fps'],
	['Cars', 'hBipZ3uIlCY'],
	['Winter', 'nf38uRi5Vnk'],
	['Christmas', '4UuegL518BI'],
	['Time', 'h6RNkQ7lU8Y'],
	['Letters1', 'BELlZKpi1Zs'],
	['Letters2', 'BELlZKpi1Zs'],
	['Letters3', 'BELlZKpi1Zs'],
	['SolarSystem', 'libKVRa01L8'],
	['SeaLife', 'tL2zlFNfbFs'],
	['Numbers', 'Vr8p_d7DJg8'],
	['Numbers2', 'Vr8p_d7DJg8'],
	['Months', 'Fe9bnYRzFvk'],
	['Days', 'mXMofxtDPUQ'],
	['FrozenABC', 'JYDI3TbWGS0'],
	['FrozenABC2', 'JYDI3TbWGS0'],
	['WordGames', 'd0Hq0fjT3r4'],
	['ColorCars', 'LKi7-ILidRg'],
	['Emotions', 'l4WNrvVjiTw'],
	['DailyRoutine', 'qD1pnquN_DM'],
	['Economics_01', 'tAM4y2xvFRQ'],
	['Seasons', '8ZjpI6fgYSY'],
	['FoodStore', '-HJ45BAkHbE'],
	['Fall', 'dKjUPqbt8DU'],
	['AnimalGame', 'CA6Mofzh7jo'],
	['WordWork', 'UpQxDSK1jrA'],
	['AMNH', 'RYVPP6mMke8'],
	['NewYearResolution', 'rqbAsr6wN_I'],
	['Biology_01', '2t_mQwTY4WQ'],
	['Dinosaurs', 'Cf5Pzb7oAa4'],
	['DreamJobs1', 'ckKQclquAXU'],
	['DreamJobs2', 'ckKQclquAXU'],
	['TheNewYear', 'QFiK3_XJHxU'],
	['Travel', 'P7kyRww9XKg'],
	['Geography01', 'eXiVGEEPQ6c'],
	['Physics01', 'EFO_bsg1sw8'],
	['HappyBirthday', 'HpWJteuRjAw'],
	['WeatherReporting', 'gpBuaU5OPi8'],
	['Zoo', 'OwRmivbNgQk'],
	['SpringFestival', 'cKoaVYALLVI'],
	['ExpressingFeelings', 'ZHS7vCdBeus'],
	['Music', '3ogepZsCnbY'],
	['MusicalIntruments', '3ogepZsCnbY'],
	['EasterDay', 'q7UXYvRRZFc'],
	['Spring21', 'QdBbBZkITxA'],
	['MotherDay', 'MYqFPa1mrXo'],
	['ChildrenDay', 'o84QFp3aJcc'],
]);

export const SLIDE_MATCH_GAME_MAP = new Map([
	['Christmas', ['Christmas']],
	['BodyGame', ['HumanBody']],
	['Fall', ['Autumn']],
	['AnimalGame', ['CuteAnimal']],
	['Emotions', ['Emotion']],
	['Weather', ['Weather']],
	['DailyRoutine', ['DailyRoutine']],
	['Animals', ['CuteAnimalHead']],
	['Animals2', ['CuteAnimalHead']],
	['WhereAreYouFrom', ['Flags1']],
	['IFeelSick', ['MedicalScience']],
	['FoodStore', ['Food1']],
	['Cars', ['Vehicles']],
	['Winter', ['Winter1']],
	['Economics_01', ['Economy']],
	['SolarSystem', ['Space']],
	['SeaLife', ['Sea3']],
	['Seasons', ['Seasons']],
	['AMNH', ['Museum']],
	['NewYearResolution', ['NewYearResolution']],
	['Biology_01', ['Covid19']],
	['Dinosaurs', ['Dinosaur']],
	['DreamJobs1', ['Profession1']],
	['DreamJobs2', ['Profession2']],
	['TheNewYear', ['NewYear']],
	['Travel', ['Travel']],
	['Geography01', ['Geography']],
	['Physics01', ['Physics']],
	['WeatherReporting', ['Weather']],
	['Zoo', ['Animal1']],
	['SpringFestival', ['ChineseNewYear']],
	['ExpressingFeelings', ['Emotion']],
	['Music', ['MusicInstruments']],
	['MusicalIntruments', ['MusicInstruments']],
	['EasterDay', ['Easter']],
	['Spring21', ['Spring']],
	['MotherDay', ['MothersDay']],
	['ChildrenDay', ['Toys']],
]);

export const DEFAULT_BOOK = 'Christmas';

export const DIR = 'slides';
