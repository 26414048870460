/** @format */

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dropdown, Grid, Divider } from 'semantic-ui-react';
import collectSlideUtils from '../utils/collectSlideUtils';
import collectWordCardUtils from '../utils/collectWordCardUtils';
import {
	WORDCARD_DISPLAY_MAP,
	WORDCARD_ARRAY,
	WORDCARD_GROUP_MAP,
} from '../constants/wordCardConfigs';

const WordCardButton = inject(
	'slideStore',
	'wordCardStore'
)(
	observer(
		class WordCardButton extends Component {
			chooseBook = (v) => {
				const newBook = this.props.wordCardStore.selectOptions[v.value.i];
				this.props.wordCardStore.setBook(newBook.book);
				this.props.wordCardStore.setImageNameList(
					collectWordCardUtils.createWordCardComponent(newBook.book)
				);
			};

			chooseLevel = (v) => {
				this.props.wordCardStore.setLevel(v.value);
				const newHeaderArray = collectSlideUtils.findHeaderByLevel(
					v.value,
					WORDCARD_ARRAY,
					WORDCARD_GROUP_MAP
				);
				const selectOptions = collectSlideUtils.getDropdownOption(
					newHeaderArray,
					WORDCARD_DISPLAY_MAP
				);
				this.props.wordCardStore.setSelectOptions(selectOptions);
			};

			render() {
				return (
					<div>
						<Grid columns={2}>
							<Grid.Column>
								<Dropdown
									text='Step1: Select a Group'
									className='button icon'
									floating
									fluid
									defaultValue={this.props.wordCardStore.defaultLevelOption}
									options={this.props.wordCardStore.levelOptions}
									onChange={(e, v) => this.chooseLevel(v)}
									labeled
									icon='folder open'
									disabled={!this.props.slideStore.user}
								/>
								<div style={{ paddingTop: '10px' }}>
									<Divider horizontal inverted>
										{this.props.wordCardStore.level}
									</Divider>
								</div>
							</Grid.Column>
							<Grid.Column>
								<Dropdown
									text='Step2: Select a Topic'
									className='button icon'
									floating
									fluid
									defaultValue={this.props.wordCardStore.defaultSelectOption}
									options={this.props.wordCardStore.selectOptions}
									onChange={(e, v) => this.chooseBook(v)}
									labeled
									icon='gamepad'
									disabled={!this.props.slideStore.user}
								/>
								<div style={{ paddingTop: '10px' }}>
									<Divider horizontal inverted>
										{' '}
										{WORDCARD_DISPLAY_MAP.get(this.props.wordCardStore.book)}
									</Divider>
								</div>
							</Grid.Column>
						</Grid>
					</div>
				);
			}
		}
	)
);

export default WordCardButton;
