/** @format */

import React, { Component } from 'react';
import Crossword from '@jaredreisinger/react-crossword';
import { Button } from 'semantic-ui-react';

class CrosswordView extends Component {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
	}

	componentDidUpdate = () => {
		this.ref.current.reset();
	};

	celebrateCorrect = () => {};
	render() {
		return (
			<div style={{ textAlign: 'left' }}>
				<Crossword
					data={this.props.gameData}
					theme={{
						gridBackground: 'rgba(0,0,0,0)',
						cellBackground: '#612e1fff',
						cellBorder: '#ffecd7',
						textColor: '#ffecd7',
						numberColor: 'rgba(255,255,255)',
						focusBackground: '#f28a1eff',
						highlightBackground: '#784c33',
					}}
					onCorrect={this.celebrateCorrect()}
					ref={this.ref}
				/>
				<Button.Group widths={2}>
					<Button
						content='Reset'
						onClick={() => this.ref.current.reset()}
						color='pink'
					/>
					<Button
						content='Fill Answers'
						onClick={() => this.ref.current.fillAllAnswers()}
						color='olive'
					/>
				</Button.Group>
			</div>
		);
	}
}

export default CrosswordView;
