/** @format */

import {
	CROSSWORD_ARRAY,
	CROSSWORD_DISPLAY_MAP,
} from '../constants/crosswordConfigs';
import slideStore from '../stores/slideStore';

const initializeData = () => {
	slideStore.setDefaultCrosswordOption(getDropdownOption()[0]);
	const dropdownOptions = getDropdownOption();
	slideStore.setCrosswordSelectOptions(dropdownOptions);
	slideStore.setCrosswordGame(slideStore.defaultCrosswordOption.game);
};

const getDropdownOption = () => {
	const options = [];
	for (let i = 0; i < CROSSWORD_ARRAY.length; i++) {
		options.push({
			key: Math.floor(Math.random() * 100000000),
			text: CROSSWORD_DISPLAY_MAP.get(CROSSWORD_ARRAY[i]),
			value: { i },
			game: CROSSWORD_ARRAY[i],
		});
	}
	return options;
};

export default {
	getDropdownOption,
	initializeData,
};
