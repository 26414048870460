/** @format */

import { observable, makeAutoObservable, reaction } from 'mobx';
import collectDialogueUtils from '../utils/collectDialogueUtils';
import slideStore from './slideStore';

class DialogueStore {
	constructor() {
		makeAutoObservable(this);
	}

	// slide
	@observable selectOptions;
	@observable levelOptions;
	@observable book;
	@observable level;
	@observable defaultSelectOption;
	@observable defaultLevelOption;

	setSelectOptions(selectOptions) {
		this.selectOptions = selectOptions;
	}

	setDefaultSelectOptions(defaultSelectOption) {
		this.defaultSelectOption = defaultSelectOption;
	}

	setLevelOptions(levelOptions) {
		this.levelOptions = levelOptions;
	}

	setDefaultLevelOptions(defaultLevelOption) {
		this.defaultLevelOption = defaultLevelOption;
	}

	setBook(book) {
		this.book = book;
	}

	setLevel(level) {
		this.level = level;
	}
}

const dialogueStore = new DialogueStore();

reaction(
	() => Object.values(slideStore.user || ''),
	() => {
		collectDialogueUtils.initializeData(slideStore.isLoggedIn);
	}
);

export default dialogueStore;
