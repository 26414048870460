/** @format */

const Letters08 = [
	'40172.jpg',
	'40186.jpg',
	'40205.jpg',
	'40224.jpg',
	'40227.jpg',
	'40237.jpg',
	'40255.jpg',
	'40270.jpg',
	'40310.jpg',
	'40313.jpg',
	'40323.jpg',
];
export default Letters08;
