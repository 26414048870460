/** @format */
import React, { useState } from 'react';
import _ from 'lodash';
import SlideShow from './SlideShow';
import { Dropdown, Grid, Image, Divider } from 'semantic-ui-react';
import VideoView from './VideoView';
import {
	CARTOON_VIDEO_MAP,
	CARTOON_FOLDER_MAP,
	DEFAULT_ARRAY,
} from '../constants/cartoonConfigs';

function CartoonImageView(props) {
	const [level, setLevel] = useState(props.defaultLevel);
	const [array, setArray] = useState(DEFAULT_ARRAY);

	const getOptionList = () => {
		const optionList = [];
		for (const [key, value] of Object.entries(props.fileList)) {
			optionList.push({ key: key, text: key, value: key });
		}
		return optionList;
	};

	const getArray = (level) => {
		const componentArray = [];
		const imageList = props.fileList[level];
		for (let i = 0; i < imageList.length; i++) {
			componentArray.push({
				original: require(`../assets/${props.folder}/${level}/${imageList[i]}`)
					.default,
				thumbnail: require(`../assets/${props.folder}/${level}/${imageList[i]}`)
					.default,
			});
		}
		return componentArray;
	};

	return (
		<div>
			<div style={{ paddingBottom: '10px' }}>
				<Grid>
					<Grid.Column textAlign='left' width={16}>
						<Dropdown
							text='Select a Topic'
							className='button icon'
							floating
							defaultValue={props.level}
							options={getOptionList()}
							onChange={(e, v) => {
								setLevel(v.value);
								setArray(getArray(v.value));
							}}
							fluid
							labeled
							icon='gamepad'
							disabled={props.disable}
						/>
					</Grid.Column>
				</Grid>
				<div style={{ paddingTop: '10px' }}>
					<Divider horizontal inverted>
						{level}
					</Divider>
				</div>
			</div>
			<SlideShow slideArray={array} />
			<div style={{ paddingTop: '10px' }}>
				<Divider horizontal inverted>
					{level}
				</Divider>
			</div>
			<VideoView
				book={CARTOON_FOLDER_MAP.get(level)}
				videoMap={CARTOON_VIDEO_MAP}
			/>
		</div>
	);
}

export default CartoonImageView;
