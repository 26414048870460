/** @format */

import l4 from './Letters04';
import l5 from './Letters05';
import l6 from './Letters06';
import l7 from './Letters07';
import l8 from './Letters08';
import l9 from './Letters09';

export const scrambleFileList = {
	'4_letters_and_fewer': l4,
	'5_letters': l5,
	'6_letters': l6,
	'7_letters': l7,
	'8_letters': l8,
	'9_letters_and_more': l9,
};

export const scrambleDefaultLevel = '7_letters';
