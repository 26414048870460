/** @format */

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dropdown, Grid, Divider } from 'semantic-ui-react';
import collectSlideUtils from '../utils/collectSlideUtils';
import collectSlideGalleryUtils from '../utils/collectSlideGalleryUtils';
import SearchBar from './SearchBar';
import {
	TOPIC_PAGE_NUM,
	TOPIC_DISPLAY_MAP,
	TOPIC_ARRAY,
	TOPIC_GROUP_MAP,
	DIR,
} from '../constants/gallerySlideConfigs';

const SlideButton = inject(
	'slideStore',
	'galleryStore'
)(
	observer(
		class SlideButton extends Component {
			chooseBook = (v) => {
				const newBook = this.props.galleryStore.selectOptions[v.value.i];
				this.props.galleryStore.setBook(newBook.book);
				this.props.galleryStore.setSlideArray(
					collectSlideUtils.getComponentArray(
						newBook.book,
						TOPIC_PAGE_NUM.get(newBook.book),
						DIR
					)
				);
			};

			chooseLevel = (v) => {
				this.props.galleryStore.setLevel(v.value);
				const newHeaderArray = collectSlideUtils.findHeaderByLevel(
					v.value,
					TOPIC_ARRAY,
					TOPIC_GROUP_MAP
				);
				const selectOptions = collectSlideUtils.getDropdownOption(
					newHeaderArray,
					TOPIC_DISPLAY_MAP
				);
				this.props.galleryStore.setSelectOptions(selectOptions);
			};

			onSelectSearchResult = (key) => {
				const newTopic = TOPIC_GROUP_MAP.get(key);
				this.chooseLevel({ value: newTopic });
				this.props.galleryStore.setBook(key);
				this.props.galleryStore.setSlideArray(
					collectSlideUtils.getComponentArray(key, TOPIC_PAGE_NUM.get(key), DIR)
				);
			};

			render() {
				return (
					<div>
						<Grid>
							<Grid.Column width={6}>
								<Dropdown
									text='Step1: Select a Topic'
									className='button icon'
									floating
									fluid
									defaultValue={this.props.galleryStore.defaultLevelOption}
									options={this.props.galleryStore.levelOptions}
									onChange={(e, v) => this.chooseLevel(v)}
									labeled
									icon='folder open'
									disabled={!this.props.slideStore.user}
								/>
								<div div style={{ paddingTop: '10px' }}>
									<Divider horizontal inverted>
										{this.props.galleryStore.level}
									</Divider>
								</div>
							</Grid.Column>
							<Grid.Column width={6}>
								<Dropdown
									text='Step2: Select a Book'
									className='button icon'
									floating
									fluid
									defaultValue={this.props.galleryStore.defaultSelectOption}
									options={this.props.galleryStore.selectOptions}
									onChange={(e, v) => this.chooseBook(v)}
									labeled
									icon='book'
									disabled={!this.props.slideStore.user}
								/>
								<div div style={{ paddingTop: '10px' }}>
									<Divider horizontal inverted>
										{' '}
										{TOPIC_DISPLAY_MAP.get(this.props.galleryStore.book)}
									</Divider>
								</div>
							</Grid.Column>
							<Grid.Column width={4}>
								<SearchBar
									logged={!!this.props.slideStore.user}
									onSelect={this.onSelectSearchResult}
									options={collectSlideGalleryUtils.getSearchOptions()}
								/>
							</Grid.Column>
						</Grid>
					</div>
				);
			}
		}
	)
);

export default SlideButton;
