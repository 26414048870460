/** @format */

import { observable, computed, makeAutoObservable } from 'mobx';
import { LEVELS, DEFAULT_BOOK, DEFAULT_LEVEL, SLIDE_INFO } from '../constants/classAwSlideConfigs';

class AwStore {

	@observable book;
	@observable level;

	constructor() {
		makeAutoObservable(this);
		this.book = DEFAULT_BOOK;
		this.level = DEFAULT_LEVEL;
	}

	setBook(book) {
		this.book = book;
	}

	setLevel(level) {
		this.level = level;
	}

	@computed
	get levelOptions() {
		const options = [];
		for (const [key, value] of Object.entries(LEVELS)) {
			options.push({
				key: Math.floor(Math.random() * 100000000),
				text: value,
				value: value,
			});
		}
		return options;
	}

	@computed
	get defaultLevelOptions() {
		return [this.levelOptions[1]];
	}

	@computed
	get selectedSlides() {
		return Object.entries(SLIDE_INFO).map(([key, value]) => value).filter(value => value.level === this.level)
	}

	@computed
	get selectOptions() {
		const options = [];
		for (let i = 0; i < this.selectedSlides.length; i++) {
			options.push({
				key: this.selectedSlides[i].key,
				text: this.selectedSlides[i].name,
				value: this.selectedSlides[i],
			});
		}
		return options;
	}

	@computed
	get slideArray() {
		const slideNameArray = this.createPhotoNameArray(this.book.dir, this.book.startId, this.book.count);
		let componentArray = [];
		for (let i = 0; i < this.book.count; i++) {
			const fileName = slideNameArray[i];
			componentArray.push({
				original: require(`../assets/aw/${this.book.dir}/${fileName}`).default,
				thumbnail: require(`../assets/aw/${this.book.dir}/${fileName}`).default,
			});
		}
		return componentArray;
	}

	createPhotoNameArray = (dir, startNum, pageNum) => {
		const nameArray = [];
		for (let i = startNum; i <= startNum + pageNum; i++) {
			let fileName = `${dir}_`;
			if (i < 10) {
				fileName += `0${i}.png`;
			} else {
				fileName += `${i}.png`;
			}
			nameArray.push(fileName);
		}
		return nameArray;
	};
}

const awStore = new AwStore();

export default awStore;
