/** @format */

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { Container } from 'semantic-ui-react';
import config from './config';
import Login from './components/Login';
import MainView from './components/MainView';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import Snow from './components/visual/Snow';
import LightRope from './components/visual/LightRope';
import collectSlideUtils from './utils/collectSlideUtils';
import collectSlideGalleryUtils from './utils/collectSlideGalleryUtils';
import collectDialogueUtils from './utils/collectDialogueUtils';
import collectMusicUtils from './utils/collectMusicUtils';
import crosswordUtils from './utils/crosswordUtils';
import imageGameUtils from './utils/imageGameUtils';
import collectWordCardUtils from './utils/collectWordCardUtils';
import CacheBuster from './utils/CacheBuster';

export default class App extends Component {
	constructor(props) {
		super(props);
		collectSlideUtils.initializeData(false);
		collectSlideGalleryUtils.initializeData(false);
		collectDialogueUtils.initializeData(false);
		collectMusicUtils.initializeData(false);
		crosswordUtils.initializeData();
		imageGameUtils.initializeData(false);
		collectWordCardUtils.initializeData(false);
	}

	componentDidMount() {
		this.props.hideLoader();
	}

	render() {
		return (
			<CacheBuster>
				{({ loading, isLatestVersion, refreshCacheAndReload }) => {
					if (loading) return null;
					if (!loading && !isLatestVersion) {
						refreshCacheAndReload();
					}
					return (
						<div>
							<div
								style={{
									marginTop: '-30px',
								}}>
								{/* <LightRope /> */}
							</div>
							<Router>
								<Security {...config.oidc}>
									{/* <div
								style={{
									paddingTop: '40px',
								}}/> */}
									<Navbar />
									<Container text>
										<Switch>
											<Route path='/' exact component={Login} />
											<Route
												path='/implicit/callback'
												component={LoginCallback}
											/>
											{/* <SecureRoute path="/profile" component={Profile} /> */}
											{/* <Route component={NotFound} /> */}
										</Switch>
										<MainView />
									</Container>
									<Footer version={global.appVersion} />
								</Security>
							</Router>
							{/* <Snow /> */}
							{/* <LightRope /> */}
						</div>
					);
				}}
			</CacheBuster>
		);
	}
}
