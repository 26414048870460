/** @format */

import Animal from './Animal';
import Color from './Color';
import Home from './Home';
import Landscape from './Landscape';
import Outdoor from './Outdoor';
import Playground from './Playground';
import School from './School';
import Shop from './Shop';
import Sport from './Sport';
import Tale from './Tale';
import Traffic from './Traffic';
import Working from './Working';

export const sceneFileList = {
	Animal: Animal,
	Color: Color,
	Home: Home,
	Landscape: Landscape,
	Outdoor: Outdoor,
	Playground: Playground,
	School: School,
	Shop: Shop,
	Sport: Sport,
	Tale: Tale,
	Traffic: Traffic,
	Working: Working,
};

export const sceneDefaultLevel = 'Sport';
