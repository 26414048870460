/** @format */

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dropdown, Grid, Divider } from 'semantic-ui-react';
import collectSlideUtils from '../utils/collectSlideUtils';
import collectMusicUtils from '../utils/collectMusicUtils';
import {
	MUSIC_DISPLAY_MAP,
	MUSIC_ARRAY,
	MUSIC_GROUP_MAP,
	MUSIC_LYRIC,
} from '../constants/musicConfigs';

const MusicButton = inject(
	'slideStore',
	'musicStore'
)(
	observer(
		class SlideButton extends Component {
			chooseBook = (v) => {
				const newBook = this.props.musicStore.selectOptions[v.value.i];
				this.props.musicStore.setBook(newBook.book);
				if (MUSIC_LYRIC.has(this.props.musicStore.book)) {
					this.props.musicStore.setLyric(
						collectMusicUtils.createLyric(this.props.musicStore.book)
					);
				} else {
					this.props.musicStore.setLyric(undefined);
				}
			};

			chooseLevel = (v) => {
				this.props.musicStore.setLevel(v.value);
				const newHeaderArray = collectSlideUtils.findHeaderByLevel(
					v.value,
					MUSIC_ARRAY,
					MUSIC_GROUP_MAP
				);
				const selectOptions = collectSlideUtils.getDropdownOption(
					newHeaderArray,
					MUSIC_DISPLAY_MAP
				);
				this.props.musicStore.setSelectOptions(selectOptions);
			};

			render() {
				return (
					<div>
						<Grid columns={2}>
							<Grid.Column>
								<Dropdown
									text='Step1: Select a Group'
									className='button icon'
									floating
									fluid
									defaultValue={this.props.musicStore.defaultLevelOption}
									options={this.props.musicStore.levelOptions}
									onChange={(e, v) => this.chooseLevel(v)}
									labeled
									icon='folder open'
									disabled={!this.props.slideStore.user}
								/>
								<div div style={{ paddingTop: '10px' }}>
									<Divider horizontal inverted>
										{this.props.musicStore.level}
									</Divider>
								</div>
							</Grid.Column>
							<Grid.Column>
								<Dropdown
									text='Step2: Select Music'
									className='button icon'
									floating
									fluid
									defaultValue={this.props.musicStore.defaultSelectOption}
									options={this.props.musicStore.selectOptions}
									onChange={(e, v) => this.chooseBook(v)}
									labeled
									icon='music'
									disabled={!this.props.slideStore.user}
								/>
								<div div style={{ paddingTop: '10px' }}>
									<Divider horizontal inverted>
										{' '}
										{MUSIC_DISPLAY_MAP.get(this.props.musicStore.book)}
									</Divider>
								</div>
							</Grid.Column>
						</Grid>
					</div>
				);
			}
		}
	)
);

export default MusicButton;
