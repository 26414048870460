/** @format */

export const TAGS = {
	TIME: 'time',
	INTERVIEW: 'interview',
	BUSINESS: 'business',
	MEETING: 'meeting',
	COLOR: 'color',
	VEHICLE: 'vehicle',
	SALES: 'sales',
	FOOD: 'food',
	COMPUTATION: 'computation',
	JOB: 'job',
}

export const LEVELS = [1, 2, 3]

export const DEFAULT_LEVEL = 2;
export const PARENT = 'aw';

export const SLIDE_INFO = {
	time: {
		key: "time",
		venue: "office",
		dir: "AcornOffice",
		name: "Time",
		tag: [TAGS.TIME],
		level: LEVELS[0],
		startId: 0,
		count: 13
	},
	interview: {
		key: "interview",
		venue: "office",
		dir: "AcornOffice",
		name: "Interview",
		tag: [TAGS.INTERVIEW],
		level: LEVELS[1],
		startId: 13,
		count: 18
	},
	meeting: {
		key: "meeting",
		venue: "office",
		dir: "AcornOffice",
		name: "Monthly meeting",
		tag: [TAGS.MEETING],
		level: LEVELS[2],
		startId: 31,
		count: 17
	},
	color: {
		key: "color",
		venue: "mall",
		dir: "AcornMallCarpark",
		name: "Color of cars",
		tag: [TAGS.COLOR],
		level: LEVELS[0],
		startId: 0,
		count: 16
	},
	vehicles: {
		key: "vehicles",
		venue: "mall",
		dir: "AcornMallCarpark",
		name: "Types of vehicles",
		tag: [TAGS.VEHICLE],
		level: LEVELS[1],
		startId: 16,
		count: 16
	},
	sales: {
		key: "color",
		venue: "mall",
		dir: "AcornMallCarpark",
		name: "Sell a car",
		tag: [TAGS.SALES],
		level: LEVELS[2],
		startId: 32,
		count: 20
	},
	breakfast: {
		key: "breakfast",
		venue: "mall",
		dir: "AcornMallFastFood",
		name: "What to eat for breakfast",
		tag: [TAGS.FOOD],
		level: LEVELS[0],
		startId: 0,
		count: 15
	},
	buyFood: {
		key: "buyFood",
		venue: "mall",
		dir: "AcornMallFastFood",
		name: "Buy breakfast",
		tag: [TAGS.COMPUTATION],
		level: LEVELS[1],
		startId: 15,
		count: 15
	},
	job: {
		key: "job",
		venue: "mall",
		dir: "AcornMallFastFood",
		name: "Work in a restaurant",
		tag: [TAGS.JOB],
		level: LEVELS[2],
		startId: 30,
		count: 15
	},
}

export const DEFAULT_BOOK = SLIDE_INFO.interview;