/** @format */

import React, { useState, useImperativeHandle } from 'react';
import ReactCardFlip from 'react-card-flip';
import { Card, Image } from 'semantic-ui-react';

const WordCardFlipCard = React.forwardRef((props, ref) => {
	const [flipped, setFlipped] = useState(props.isFlipped);

	useImperativeHandle(ref, () => ({
		validate() {
			setFlipped(false);
		},
	}));

	const textArray = props.file.back.split(' ');
	const componentList = [];
	const unit = 20;
	let yAxis =
		textArray.length > 2 ? 64 - Math.floor(textArray.length / 2) * unit : 64;
	textArray.forEach((part) => {
		componentList.push(
			<tspan x='64' y={yAxis}>
				{part}
			</tspan>
		);
		yAxis += unit;
	});

	return (
		<ReactCardFlip isFlipped={flipped} flipDirection='vertical'>
			<Card onClick={() => setFlipped(!flipped)}>
				<Image
					src={
						require(`../../assets/wordCardImage/${props.topic}/${props.file.front}`)
							.default
					}
					wrapped
					ui={false}
				/>
			</Card>
			<Card onClick={() => setFlipped(!flipped)}>
				<svg viewBox='0 0 128.002 128.002'>
					<text x='64' y='64' width={128} textAnchor='middle'>
						{componentList}
					</text>
				</svg>
			</Card>
		</ReactCardFlip>
	);
});

export default WordCardFlipCard;
