/** @format */
import React, { useState } from 'react';
import _ from 'lodash';
import {
	Dropdown,
	Grid,
	Button,
	Image,
	Divider,
	Pagination,
} from 'semantic-ui-react';
import { COLOR_THEME, CURRENT_THEME } from '../../constants/constants';

function RandomImageView(props) {
	const [level, setLevel] = useState(props.defaultLevel);
	const [image, setImage] = useState(0);
	const getOptionList = () => {
		const optionList = [];
		for (const [key, value] of Object.entries(props.fileList)) {
			optionList.push({ key: key, text: key, value: key });
		}
		return optionList;
	};
	let totalImageAmount = props.fileList[level].length;
	const getRandomIndex = () => {
		return _.random(0, totalImageAmount - 1);
	};
	return (
		<div>
			<div style={{ paddingBottom: '10px' }}>
				<Grid>
					<Grid.Column textAlign='left' width={6}>
						<Dropdown
							text='Select a Topic'
							className='button icon'
							floating
							defaultValue={props.level}
							options={getOptionList()}
							onChange={(e, v) => {
								setLevel(v.value);
								setImage(0);
							}}
							fluid
							labeled
							icon='gamepad'
							disabled={props.disable}
						/>
					</Grid.Column>
					<Grid.Column textAlign='center' width={7}>
						<Pagination
							activePage={image + 1}
							firstItem={null}
							lastItem={null}
							totalPages={totalImageAmount}
							onPageChange={(e, { activePage }) => {
								setImage(activePage - 1);
							}}
							style={{
								fontSize: '0.6em',
								backgroundColor: COLOR_THEME[CURRENT_THEME]['segment'],
								color: '#ffffff',
							}}
							pointing
							inverted
						/>
					</Grid.Column>
					<Grid.Column textAlign='center' width={3}>
						<Button
							size='mid'
							color='pink'
							onClick={() => setImage(getRandomIndex())}>
							Luck Draw!
						</Button>
					</Grid.Column>
				</Grid>
				<div style={{ paddingTop: '10px' }}>
					<Divider horizontal inverted>
						{level}
					</Divider>
				</div>
			</div>
			<Image
				src={
					require(`../../assets/${props.folder}/${level}/${props.fileList[level][image]}`)
						.default
				}
			/>
		</div>
	);
}

export default RandomImageView;
