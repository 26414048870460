/** @format */

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dropdown, Grid, Divider } from 'semantic-ui-react';
import { CROSSWORD_DISPLAY_MAP } from '../../constants/crosswordConfigs';

const CrosswordButton = inject('slideStore')(
	observer(
		class CrosswordButton extends Component {
			chooseGame = (v) => {
				const newGame = this.props.slideStore.crosswordSelectOptions[v.value.i];
				this.props.slideStore.setCrosswordGame(newGame.game);
			};

			render() {
				return (
					<div>
						<Grid>
							<Grid.Column textAlign='right' width={8}>
								<Dropdown
									text='Select a Game'
									className='button icon'
									floating
									defaultValue={
										this.props.slideStore.defaultCrosswordOption.value
									}
									options={this.props.slideStore.crosswordSelectOptions}
									onChange={(e, v) => this.chooseGame(v)}
									fluid
									labeled
									disabled={!this.props.slideStore.user}
									icon='gamepad'
								/>
							</Grid.Column>
						</Grid>
						<div style={{ paddingTop: '10px' }}>
							<Divider horizontal>
								{' '}
								{CROSSWORD_DISPLAY_MAP.get(this.props.slideStore.crosswordGame)}
							</Divider>
						</div>
					</div>
				);
			}
		}
	)
);

export default CrosswordButton;
