/** @format */
import React, { Component } from 'react';

class Snow extends Component {
	render() {
		return (
			<div className='snowflakes' aria-hidden='true'>
				<div className='snowflake'>❅</div>
				<div className='snowflake'>❅</div>
				<div className='snowflake'>❆</div>
				<div className='snowflake'>❄</div>
				<div className='snowflake'>❅</div>
				<div className='snowflake'>❆</div>
				<div className='snowflake'>❄</div>
				<div className='snowflake'>❅</div>
				<div className='snowflake'>❆</div>
				<div className='snowflake'>❄</div>
			</div>
		);
	}
}

export default Snow;
