/** @format */

import { observable, computed, makeAutoObservable, reaction } from 'mobx';
import collectSlideUtils from '../utils/collectSlideUtils';
import imageGameUtils from '../utils/imageGameUtils';
import { ACORN_WORLD } from '../constants/constants';

class SlideStore {
	constructor() {
		makeAutoObservable(this);
	}

	// global
	@observable user;
	@observable content = ACORN_WORLD;

	setContent(content) {
		this.content = content;
	}

	setUser(user) {
		this.user = user;
	}

	@computed
	isLoggedIn() {
		return !!this.user;
	}

	// slide
	@observable selectOptions;
	@observable levelOptions;
	@observable slideArray;
	@observable book;
	@observable level;
	@observable defaultSelectOption;
	@observable defaultLevelOption;

	setSelectOptions(selectOptions) {
		this.selectOptions = selectOptions;
	}

	setDefaultSelectOptions(defaultSelectOption) {
		this.defaultSelectOption = defaultSelectOption;
	}

	setLevelOptions(levelOptions) {
		this.levelOptions = levelOptions;
	}

	setDefaultLevelOptions(defaultLevelOption) {
		this.defaultLevelOption = defaultLevelOption;
	}

	setSlideArray(slideArray) {
		this.slideArray = slideArray;
	}

	setBook(book) {
		this.book = book;
	}

	setLevel(level) {
		this.level = level;
	}

	// crossword
	@observable crosswordSelectOptions;
	@observable crosswordGame;
	@observable defaultCrosswordOption;

	setCrosswordSelectOptions(crosswordSelectOptions) {
		this.crosswordSelectOptions = crosswordSelectOptions;
	}

	setCrosswordGame(crosswordGame) {
		this.crosswordGame = crosswordGame;
	}

	setDefaultCrosswordOption(defaultCrosswordOption) {
		this.defaultCrosswordOption = defaultCrosswordOption;
	}

	// wordCard
	@observable imageNameList;

	setImageNameList(imageNameList) {
		this.imageNameList = imageNameList;
	}

	// maze
	@observable mazeSelectOptions;
	@observable mazeGame;
	@observable defaultMazeOption;
	@observable mazeImageGameArray;

	setMazeSelectOptions(mazeSelectOptions) {
		this.mazeSelectOptions = mazeSelectOptions;
	}

	setMazeGame(mazeGame) {
		this.mazeGame = mazeGame;
	}

	setDefaultMazeOption(defaultMazeOption) {
		this.defaultMazeOption = defaultMazeOption;
	}

	setMazeImageGameArray(mazeImageGameArray) {
		this.mazeImageGameArray = mazeImageGameArray;
	}

	// crossword image
	@observable crosswordImageSelectOptions;
	@observable crosswordImageGame;
	@observable defaultCrosswordImageOption;
	@observable crosswordImageGameArray;

	setCrosswordImageSelectOptions(crosswordImageSelectOptions) {
		this.crosswordImageSelectOptions = crosswordImageSelectOptions;
	}

	setCrosswordImageGame(crosswordImageGame) {
		this.crosswordImageGame = crosswordImageGame;
	}

	setDefaultCrosswordImageOption(defaultCrosswordImageOption) {
		this.defaultCrosswordImageOption = defaultCrosswordImageOption;
	}

	setCrosswordImageGameArray(crosswordImageGameArray) {
		this.crosswordImageGameArray = crosswordImageGameArray;
	}

	// difference
	@observable differenceSelectOptions;
	@observable differenceGame;
	@observable defaultDifferenceOption;
	@observable differenceImageGameArray;

	setDifferenceSelectOptions(differenceSelectOptions) {
		this.differenceSelectOptions = differenceSelectOptions;
	}

	setDifferenceGame(differenceGame) {
		this.differenceGame = differenceGame;
	}

	setDefaultDifferenceOption(defaultDifferenceOption) {
		this.defaultDifferenceOption = defaultDifferenceOption;
	}

	setDifferenceImageGameArray(differenceImageGameArray) {
		this.differenceImageGameArray = differenceImageGameArray;
	}

	// counting
	@observable countingSelectOptions;
	@observable countingGame;
	@observable defaultCountingOption;
	@observable countingImageGameArray;

	setCountingSelectOptions(countingSelectOptions) {
		this.countingSelectOptions = countingSelectOptions;
	}

	setCountingGame(countingGame) {
		this.countingGame = countingGame;
	}

	setDefaultCountingOption(defaultCountingOption) {
		this.defaultCountingOption = defaultCountingOption;
	}

	setCountingImageGameArray(countingImageGameArray) {
		this.countingImageGameArray = countingImageGameArray;
	}
}

const slideStore = new SlideStore();

reaction(
	() => Object.values(slideStore.user || ''),
	() => {
		collectSlideUtils.initializeData(slideStore.isLoggedIn);
		imageGameUtils.initializeData(slideStore.isLoggedIn);
	}
);

export default slideStore;
