/** @format */

import React from 'react';

const Footer = (props) => {
	return (
		<div id='footer'>
			<h6>Version: {props.version}</h6>
		</div>
	);
};

export default Footer;
