/** @format */

import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Grid, Card, Image, Pagination, Button } from 'semantic-ui-react';
import DraggableList from './DraggableList';
import genericUtils from '../../utils/genericUtils';
import { SLIDE } from '../../constants/constants';
import { SLIDE_MATCH_GAME_MAP } from '../../constants/classSlideConfigs';

const WordCardView = inject(
	'slideStore',
	'wordCardStore'
)(
	observer(function WordCardView(props) {
		const [activePage, setActivePage] = useState(1);
		const [toggle, setToggle] = useState(false);

		const book =
			props.slideStore.content === SLIDE
				? SLIDE_MATCH_GAME_MAP.get(props.slideStore.book)
				: props.wordCardStore.book;
		const imageNameList =
			props.slideStore.content === SLIDE
				? props.slideStore.imageNameList
				: props.wordCardStore.imageNameList;
		const cardComponents = [];
		let rowComponent = [];
		let index = 0;
		const LETTER_ARRAY = ['001-A.svg', '002-b.svg', '003-c.svg', '004-d.svg'];
		for (let r = 0; r < 4; r++) {
			rowComponent.push(
				<Card id='background-violet'>
					<Card.Content>
						<Image
							size='small'
							src={
								require(`../../assets/wordCardImage/AlphaNum/${LETTER_ARRAY[r]}`)
									.default
							}
						/>
					</Card.Content>
				</Card>
			);
		}
		cardComponents.push(<Grid.Column width={3}>{rowComponent}</Grid.Column>);

		rowComponent = [];
		const originalArray = [];
		for (let r = 0; r < 4; r++) {
			index = (activePage - 1) * 4 + r;
			rowComponent.push(
				<Card id='background-violet'>
					<Card.Content>
						<Image
							src={
								require(`../../assets/wordCardImage/${book}/${imageNameList[index].front}`)
									.default
							}
						/>
					</Card.Content>
				</Card>
			);
			originalArray.push(imageNameList[index].back);
		}
		cardComponents.push(<Grid.Column width={3}>{rowComponent}</Grid.Column>);

		let contentList = originalArray;
		while (JSON.stringify(contentList) === JSON.stringify(originalArray)) {
			contentList = genericUtils.shuffle(originalArray.slice());
		}

		const answerArray = [];
		originalArray.forEach((element) =>
			answerArray.push(contentList.indexOf(element))
		);

		return (
			<div>
				<Grid>
					<Grid.Row verticalAlign='middle'>
						<Grid.Column width={3}>Select a Game:</Grid.Column>
						<Grid.Column width={5} textAlign='left'>
							<Pagination
								activePage={activePage}
								firstItem={null}
								lastItem={null}
								totalPages={4}
								onPageChange={(e, { activePage }) => {
									props.resetHandler();
									setActivePage(activePage);
								}}
								style={{ fontSize: '0.5em', backgroundColor: props.pColor }}
								inverted
								pointing
							/>
						</Grid.Column>
						<Grid.Column width={3}>
							<Button
								size='mini'
								color='pink'
								onClick={() => {
									props.resetHandler();
									setToggle(!toggle);
								}}>
								Replay
							</Button>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						{cardComponents}
						<Grid.Column width={10}>
							<DraggableList
								items={contentList}
								setCompleted={() => {
									props.completeHandler();
								}}
								answer={answerArray}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);
	})
);
export default WordCardView;
