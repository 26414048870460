/** @format */

// DIALOGUEs constants
export const DIALOGUE_DISPLAY_MAP = new Map([
	['Telephone', 'Telephone Conversations'],
	['LostItem', 'Lost Item'],
	['TongueTwisters', 'Tongue Twisters'],
	['WhatColor', 'What color is it?'],
]);

export const DIALOGUE_GROUP_MAP = new Map([
	['Telephone', 'Daily Routine'],
	['LostItem', 'Daily Routine'],
	['TongueTwisters', 'Pronunciation'],
	['WhatColor', 'Daily Routine'],
]);

export const DIALOGUE_ARRAY = [
	'Telephone',
	'LostItem',
	'TongueTwisters',
	'WhatColor',
];

export const DIALOGUE_VIDEO_MAP = new Map([
	['Telephone', 'gWOqA3pUaTk'],
	['LostItem', 'sdRFq8rPEyM'],
	['TongueTwisters', 'NERvH60UcHQ'],
	['WhatColor', '3Sq8ibmgmS8'],
]);

export const DEFAULT_DIALOGUE = 'Telephone';
