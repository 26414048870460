/** @format */

import React from 'react';
import { Embed } from 'semantic-ui-react';

export default function VideoView(props) {
	return (
		<Embed
			autoplay={false}
			color='white'
			iframe={{
				allowFullScreen: true,
			}}
			id={props.videoMap.get(props.book)}
			placeholder={require(`../assets/videos/${props.book}/ph01.png`).default}
			source='youtube'
		/>
	);
}
