/** @format */

// TOPICs constants
export const TOPIC_DISPLAY_MAP = new Map([
	['CanYouSee', '[Age 03-05] Can you see?'],
	['FoodChain', '[Age 09-12] Food Chain'],
	['Night', '[Age 06-09] Night'],
	['SelectPets', '[Age 07-10] Select Pets'],
	['Balloon', '[Age 03-05] Balloon'],
	['Home', '[Age 06-09] A Perfect Home'],
	['Diggers', '[Age 03-05] Diggers'],
	['ZoomOut', '[Age 08-11] ZoomOut'],
	['WhoEatsWhat', '[Age 08-11] Who Eats What?'],
	['BearFamily', '[Age 06-09] Bear Family'],
	['SharePizza', '[Age 07-09] Share Pizza'],
	['MallEvent', '[Age 07-09] Mall Event'],
	['TheToysParty', "[Age 03-05] The Toys' Party"],
	['Quiz', '[Age 03-05] Quiz'],
	['PlantsForDinner', '[Age 06-08] Plants For Dinner'],
	['StinkyPlants', '[Age 06-08] Stinky Plants'],
	['TomDadColin', '[Age 07-09] Tom, Dad and Colin'],
	['MakeGoldfishTank', '[Age 03-05] Make a Goldfish Tank'],
	['HomeForOneNight', '[Age 06-08] Camping'],
	['LetsGo', "[Age 03-05] Let's Go"],
	['SilverFoilRocket', '[Age 05-07]  Silver Foil Rocket'],
	['DadCanYouDoThis', '[Age 03-05] Dad, can you do this?'],
	['AFlightToNYC', '[Age 06-09]  A Flight To New York'],
	['DinosaurSafari', '[Age 09-12] Dinosaur Safari'],
	['OurPlanet', '[Age 07-10] Our Planet'],
	['ThingsThatSting', '[Age 06-09] Things That Sting'],
	['AnimalMagic', '[Age 07-09] Animal Magic'],
	['EveryoneGotWet', '[Age 05-07] Everyone Got Wet'],
	['Planets', '[Age 07-10] Planets'],
	['SaveTheWhale', '[Age 05-07] Save The Whale'],
	['InWoods', "[Age 05-07] What's In The Woods?"],
	['DeepSeaCreature', '[Age 08-11] Deep Sea Creature'],
	['AHouseFitMouse', '[Age 03-05] A House Fit Mouse'],
	['BigBadBug', '[Age 03-05] Big Bad Bug'],
	['DadBirthday', "[Age 03-05] Dad's Birthday"],
	['DoNotSneeze', '[Age 05-07] Do Not Sneeze'],
	['ExploreTheDeep', '[Age 12-15] Explore The Deep'],
	['IncredibleAnimals', '[Age 07-10] Incredible Animals'],
	['LeekHotPot', '[Age 05-07] Leek Hotpot'],
	['PaintingTheLoft', '[Age 05-07] Painting The Loft'],
	['SpecialDays', '[Age 09-15] Special Days'],
	['TheFrogPrince', '[Age 07-10] The Frog Prince'],
	['TheScarf', '[Age 05-07] The Scarf'],
	['Multiplication', '[Age 05-07] Simple Multiplication'],
	['Sorting', '[Age 06-08] Sorting'],
	['Order', '[Age 06-08] Ordering'],
	['Estimate', '[Age 07-09] Estimate'],
	['CountCouple', '[Age 05-07] Count Couple'],
	['Pattern', '[Age 06-08] Discover Patterns'],
	['3DShape', '[Age 06-08] 3D Shape'],
	['2DShape', '[Age 05-07] 2D Shape'],
	['Fraction', '[Age 05-07] Fraction'],
	['Division', '[Age 05-07] Simple Division'],
	['Subtraction', '[Age 03-05] Simple Subtraction'],
	['Addition', '[Age 03-05] Simple Addition'],
	['SpringBook', '[Age 06-09] Spring'],
	['SummerBook', '[Age 06-09] Summer'],
	['AutumnBook', '[Age 06-09] Autumn'],
	['WinterBook', '[Age 06-09] Winter'],
	['SuperShadows', '[Age 08-12] Basic Physics - Super Shadows'],
	['WildWater', '[Age 08-12] Basic Physics - Wild Water'],
	['AwesomeAir', '[Age 08-12] Basic Physics - Awesome Air'],
	['DiggingDirt', '[Age 08-12] Basic Physics - Digging Dirt'],
	['GroovyGravity', '[Age 08-12] Basic Physics - Groovy Gravity'],
	['RadicalRocks', '[Age 08-12] Basic Physics - Radical Rocks'],
	['CrazyClouds', '[Age 08-12] Basic Physics - Crazy Clouds'],
	[
		'ElectricityIsEverywhere',
		'[Age 08-12] Basic Physics - Electricity Is Everywhere',
	],
	['ExcitingEnergy', '[Age 08-12] Basic Physics - Exciting Energy'],
	['MarvelousMotion', '[Age 08-12] Basic Physics - Marvelous Motion'],
	['MightyMagnets', '[Age 08-12] Basic Physics - Mighty Magnets'],
	['StupendousSound', '[Age 08-12] Basic Physics - Stupendous Sound'],
	['FeverPoles', '[Age 10-15] Climate Change - Fever Poles'],
	['FeverInTheOceans', '[Age 10-15] Climate Change - Fever In The Oceans'],
	['EarthFever', '[Age 10-15] Climate Change - Earth Fever'],
	['FeverLand', '[Age 10-15] Climate Change - Fever Land'],
	['DinosaursArmoredReptiles', '[Age 10-15] Dinosaurs - Armored Reptiles'],
	['DinosaursCarnivores', '[Age 10-15] Dinosaurs - Carnivores'],
	['DinosaursHerbivores', '[Age 10-15] Dinosaurs - Herbivores'],
	['DinosaursSeaReptiles', '[Age 10-15] Dinosaurs - Sea Reptiles'],
	['DinosaursFlyingRetiples', '[Age 10-15] Dinosaurs - Flying Reptiles'],
	['DinosaursFossilHunters', '[Age 10-15] Dinosaurs - Fossil Hunters'],
	['FoodChainCity', '[Age 10-15] Food Chain - City'],
	['FoodWeb', '[Age 10-15] FoodWeb'],
	['FoodChainOcean', '[Age 10-15] Food Chain - Ocean'],
	['FoodChainForest', '[Age 10-15] Food Chain - Forest'],
	['FoodChainDesert', '[Age 10-15] Food Chain - Desert'],
	['FoodChainGrassland', '[Age 10-15] Food Chain - Grassland'],
	['MaterialWood', '[Age 08-12] Material - Wood'],
	['MaterialPaper', '[Age 08-12] Material - Paper'],
	['MaterialMetal', '[Age 08-12] Material - Matel'],
	['MaterialCloth', '[Age 08-12] Material - Cloth'],
	['MaterialPlastic', '[Age 08-12] Material - Plastic'],
	['MaterialGlass', '[Age 08-12] Material - Glass'],
	['IncredibleAnimalsSpeeders', '[Age 10-15] Incredible Animals - Speeders'],
	['IncredibleAnimalsPredators', '[Age 10-15] Incredible Animals - Predators'],
	['IncredibleAnimalsMigrators', '[Age 10-15] Incredible Animals - Migrators'],
	[
		'IncredibleAnimalsHibernators',
		'[Age 10-15] Incredible Animals - Hibernators',
	],
	['IncredibleAnimalsBuilders', '[Age 10-15] Incredible Animals - Builders'],
	[
		'IncredibleAnimalsCamouflage',
		'[Age 10-15] Incredible Animals - Camouflage',
	],
	['EngineeringRoads', '[Age 08-12] Engineering - Roads'],
	['EngineeringDams', '[Age 08-12] Engineering - Dams'],
	['EngineeringTunnels', '[Age 08-12] Engineering - Tunnels'],
	['EngineeringSkyscrapers', '[Age 08-12] Engineering - Skyscrapers'],
	['EngineeringCanals', '[Age 08-12] Engineering - Canals'],
	['EngineeringBridges', '[Age 08-12] Engineering - Bridges'],
	['SeaStars', '[Age 10-15] Sea Stars'],
	['CocoaBean2Chocolate', '[Age 12-15] CocoaBean to Chocolate'],
	['Cane2Sugar', '[Age 12-15] Cane to Sugar'],
	['Milk2Icecream', '[Age 12-15] Milk to Icecream'],
	['Oil2Gas', '[Age 12-15] Oil to Gas'],
	['TheElectricCar', '[Age 07-11] The Electric Cars'],
	['Drones', '[Age 07-11] Drones'],
	['GPS', '[Age 07-11] GPS'],
	['3DPrinting', '[Age 07-11] 3DPrinting'],
	['Robots', '[Age 07-11] Robots'],
	['AdSea', '[Age 12-15] Sea Creature'],
	['AdSpace', '[Age 12-15] Space'],
	['AdFlights', '[Age 12-15] Flights'],
	['AdStorm', '[Age 12-15] Storm'],
	['AdCave', '[Age 12-15] Cave'],
	['AdGhosts', '[Age 12-15] Ghosts'],
	['AdWarriors', '[Age 12-15] Warriors'],
	['AdPirates', '[Age 12-15] Pirates'],
	['AdDinosaurs', '[Age 12-15] Dinosaurs'],
	['AdMonsters', '[Age 12-15] Monsters'],
	['AdBugs', '[Age 12-15] Bugs'],
	['AdBeasts', '[Age 12-15] Beasts'],
	['InsideBones', '[Age 12-15] Inside the Bones'],
	['InsideBlood', '[Age 12-15] Inside the Blood'],
	['InsideKidneys', '[Age 12-15] Inside the Kidneys'],
	['InsideMuscles', '[Age 12-15] Inside the Muscles'],
	['InsideSkinHairNails', '[Age 12-15] Inside the Skin, Hair and Nails'],
	['InsideGerms', '[Age 12-15] Inside the Germs'],
	['InsideBrain', '[Age 12-15] Inside the Brain'],
	['InsideEars', '[Age 12-15] Inside the Ears'],
	['InsideEyes', '[Age 12-15] Inside the Eyes'],
	['InsideHeart', '[Age 12-15] Inside the Heart'],
	['InsideLungs', '[Age 12-15] Inside the Lungs'],
	['InsideStomach', '[Age 12-15] Inside the Stomach'],
	['Mass', '[Age 07-10] Mass'],
	['Electricity', '[Age 07-10] Electricity'],
	['Elements', '[Age 07-10] Elements'],
	['Light', '[Age 07-10] Light'],
	['Magnetism', '[Age 07-10] Magnetism'],
	['Temperature', '[Age 07-10] Temperature'],
	['Snowing', '[Age 04-07] Weather - Snowing'],
	['Windy', '[Age 04-07] Weather - Windy'],
	['Sunny', '[Age 04-07] Weather - Sunny'],
	['Stormy', '[Age 04-07] Weather - Stormy'],
	['Cloudy', '[Age 04-07] Weather - Cloudy'],
	['Raining', '[Age 04-07] Weather - Raining'],
	['Circles', '[Age 04-06] Shapes - Circles'],
	['Squares', '[Age 04-06] Shapes - Squares'],
	['Triangles', '[Age 04-06] Shapes - Triangles'],
	['Rectangles', '[Age 04-06] Shapes - Rectangles'],
	['Crescents', '[Age 04-06] Shapes - Crescents'],
	['RhymesAnimalTales', '[Age 04-10] Rhymes - Animal Tales'],
	['RhymesBedtime', '[Age 04-10] Rhymes - Bedtime'],
	['RhymesCountAlong', '[Age 04-10] Rhymes - Count Along'],
	['RhymesColors', '[Age 04-10] Rhymes - Colors'],
	['RhymesWeather', '[Age 04-10] Rhymes - Weather'],
	['GoodMannersAtHome', '[Age 03-15] Good Manners At Home'],
	['GoodMannersAtSchool', '[Age 03-15] Good Manners At School'],
	['GoodMannersInARestaurant', '[Age 03-15] Good Manners In A Restaurant'],
	['GoodMannerInPublic', '[Age 03-15] Good Manners In Public'],
	['GoodMannersOnThePhone', '[Age 03-15] Good Manners On The Phone'],
	['GoodMannersOnThePlayground', '[Age 03-15] Good Manners On The Playground'],
	['GoodMannersWithYourFriends', '[Age 03-15] Good Manners With Your Friends'],
	[
		'GoodMannersWithYourSiblings',
		'[Age 03-15] Good Manners With Your Siblings',
	],
	[
		'GoodMannersWithYourTeachers',
		'[Age 03-15] Good Manners With Your Teachers',
	],
	['GoodMannerWithYourParents', '[Age 03-15] Good Manners With Your Parents'],
	['Monday', '[Age 03-06] Monday'],
	['Tuesday', '[Age 03-06] Tuesday'],
	['Wednesday', '[Age 03-06] Wednesday'],
	['Thursday', '[Age 03-06] Thursday'],
	['Friday', '[Age 03-06] Friday'],
	['Saturday', '[Age 03-06] Saturday'],
	['Sunday', '[Age 03-06] Sunday'],
	['January', '[Age 04-07] January'],
	['February', '[Age 04-07] February'],
	['March', '[Age 04-07] March'],
	['April', '[Age 04-07] April'],
	['May', '[Age 04-07] May'],
	['June', '[Age 04-07] June'],
	['July', '[Age 04-07] July'],
	['August', '[Age 04-07] August'],
	['September', '[Age 04-07] September'],
	['October', '[Age 04-07] October'],
	['November', '[Age 04-07] November'],
	['December', '[Age 04-07] December'],
	['NorthAmerica', '[Age 07-10] Continents - North America'],
	['SouthAmerica', '[Age 07-10] Continents - South America'],
	['Asia', '[Age 07-10] Continents - Asia'],
	['Australia', '[Age 07-10] Continents - Australia'],
	['Africa', '[Age 07-10] Continents - Africa'],
	['Antarctica', '[Age 07-10] Continents - Antarctica'],
	['Europe', '[Age 07-10] Continents - Europe'],
]);

export const TOPIC_GROUP_MAP = new Map([
	['CanYouSee', 'Story'],
	['FoodChain', 'Biology'],
	['Night', 'Nature'],
	['SelectPets', 'General Knowledge'],
	['Balloon', 'Story'],
	['Home', 'General Knowledge'],
	['Diggers', 'Industry'],
	['ZoomOut', 'Science'],
	['WhoEatsWhat', 'Biology'],
	['BearFamily', 'Maths'],
	['SharePizza', 'Maths'],
	['MallEvent', 'Maths'],
	['TheToysParty', 'Story'],
	['Quiz', 'Story'],
	['PlantsForDinner', 'General Knowledge'],
	['StinkyPlants', 'Story'],
	['TomDadColin', 'Story'],
	['MakeGoldfishTank', 'General Knowledge'],
	['HomeForOneNight', 'Story'],
	['LetsGo', 'General Knowledge'],
	['SilverFoilRocket', 'Story'],
	['DadCanYouDoThis', 'Story'],
	['AFlightToNYC', 'General Knowledge'],
	['DinosaurSafari', 'Animals'],
	['OurPlanet', 'Nature'],
	['ThingsThatSting', 'Nature'],
	['AnimalMagic', 'Animals'],
	['EveryoneGotWet', 'Story'],
	['Planets', 'Science'],
	['SaveTheWhale', 'Animals'],
	['InWoods', 'Nature'],
	['DeepSeaCreature', 'Animals'],
	['AHouseFitMouse', 'Story'],
	['BigBadBug', 'Story'],
	['DadBirthday', 'Story'],
	['DoNotSneeze', 'Story'],
	['ExploreTheDeep', 'Science'],
	['IncredibleAnimals', 'Nature'],
	['LeekHotPot', 'Story'],
	['PaintingTheLoft', 'Story'],
	['SpecialDays', 'Calendar and Seasons'],
	['TheFrogPrince', 'Story'],
	['TheScarf', 'Story'],
	['Multiplication', 'Maths'],
	['Sorting', 'Maths'],
	['Order', 'Maths'],
	['Estimate', 'Maths'],
	['CountCouple', 'Maths'],
	['Pattern', 'Maths'],
	['3DShape', 'Maths'],
	['2DShape', 'Maths'],
	['Fraction', 'Maths'],
	['Division', 'Maths'],
	['Subtraction', 'Maths'],
	['Addition', 'Maths'],
	['SpringBook', 'Calendar and Seasons'],
	['SummerBook', 'Calendar and Seasons'],
	['AutumnBook', 'Calendar and Seasons'],
	['WinterBook', 'Calendar and Seasons'],
	['SuperShadows', 'Physics'],
	['WildWater', 'Physics'],
	['AwesomeAir', 'Physics'],
	['DiggingDirt', 'Physics'],
	['GroovyGravity', 'Physics'],
	['RadicalRocks', 'Physics'],
	['CrazyClouds', 'Physics'],
	['ElectricityIsEverywhere', 'Physics'],
	['ExcitingEnergy', 'Physics'],
	['MarvelousMotion', 'Physics'],
	['MightyMagnets', 'Physics'],
	['StupendousSound', 'Physics'],
	['FeverPoles', 'Science'],
	['FeverInTheOceans', 'Science'],
	['EarthFever', 'Science'],
	['FeverLand', 'Science'],
	['DinosaursArmoredReptiles', 'Animals'],
	['DinosaursCarnivores', 'Animals'],
	['DinosaursHerbivores', 'Animals'],
	['DinosaursSeaReptiles', 'Animals'],
	['DinosaursFlyingRetiples', 'Animals'],
	['DinosaursFossilHunters', 'Science'],
	['FoodChainCity', 'Biology'],
	['FoodWeb', 'Biology'],
	['FoodChainOcean', 'Biology'],
	['FoodChainForest', 'Biology'],
	['FoodChainDesert', 'Biology'],
	['FoodChainGrassland', 'Biology'],
	['MaterialWood', 'Industry'],
	['MaterialPaper', 'Industry'],
	['MaterialMetal', 'Industry'],
	['MaterialCloth', 'Industry'],
	['MaterialPlastic', 'Industry'],
	['MaterialGlass', 'Industry'],
	['IncredibleAnimalsSpeeders', 'Animals'],
	['IncredibleAnimalsPredators', 'Animals'],
	['IncredibleAnimalsMigrators', 'Animals'],
	['IncredibleAnimalsHibernators', 'Animals'],
	['IncredibleAnimalsBuilders', 'Animals'],
	['IncredibleAnimalsCamouflage', 'Animals'],
	['EngineeringRoads', 'Engineering'],
	['EngineeringDams', 'Engineering'],
	['EngineeringTunnels', 'Engineering'],
	['EngineeringSkyscrapers', 'Engineering'],
	['EngineeringCanals', 'Engineering'],
	['EngineeringBridges', 'Engineering'],
	['SeaStars', 'Animals'],
	['CocoaBean2Chocolate', 'Industry'],
	['Cane2Sugar', 'Industry'],
	['Milk2Icecream', 'Industry'],
	['Oil2Gas', 'Industry'],
	['TheElectricCar', 'Technology'],
	['Drones', 'Technology'],
	['GPS', 'Technology'],
	['3DPrinting', 'Technology'],
	['Robots', 'Technology'],
	['AdSea', 'Advanture'],
	['AdSpace', 'Advanture'],
	['AdFlights', 'Advanture'],
	['AdStorm', 'Advanture'],
	['AdCave', 'Advanture'],
	['AdGhosts', 'Advanture'],
	['AdWarriors', 'Advanture'],
	['AdPirates', 'Advanture'],
	['AdDinosaurs', 'Advanture'],
	['AdMonsters', 'Advanture'],
	['AdBugs', 'Advanture'],
	['AdBeasts', 'Advanture'],
	['InsideBones', 'Anatomy'],
	['InsideBlood', 'Anatomy'],
	['InsideKidneys', 'Anatomy'],
	['InsideMuscles', 'Anatomy'],
	['InsideSkinHairNails', 'Anatomy'],
	['InsideGerms', 'Anatomy'],
	['InsideBrain', 'Anatomy'],
	['InsideEars', 'Anatomy'],
	['InsideEyes', 'Anatomy'],
	['InsideHeart', 'Anatomy'],
	['InsideLungs', 'Anatomy'],
	['InsideStomach', 'Anatomy'],
	['Mass', 'Science'],
	['Electricity', 'Science'],
	['Elements', 'Science'],
	['Light', 'Science'],
	['Magnetism', 'Science'],
	['Temperature', 'Science'],
	['Snowing', 'General Knowledge'],
	['Windy', 'General Knowledge'],
	['Sunny', 'General Knowledge'],
	['Stormy', 'General Knowledge'],
	['Cloudy', 'General Knowledge'],
	['Raining', 'General Knowledge'],
	['Circles', 'Maths'],
	['Squares', 'Maths'],
	['Triangles', 'Maths'],
	['Rectangles', 'Maths'],
	['Crescents', 'Maths'],
	['RhymesAnimalTales', 'Rhymes'],
	['RhymesBedtime', 'Rhymes'],
	['RhymesCountAlong', 'Rhymes'],
	['RhymesColors', 'Rhymes'],
	['RhymesWeather', 'Rhymes'],
	['GoodMannersAtHome', 'Daily Routine'],
	['GoodMannersAtSchool', 'Daily Routine'],
	['GoodMannersInARestaurant', 'Daily Routine'],
	['GoodMannerInPublic', 'Daily Routine'],
	['GoodMannersOnThePhone', 'Daily Routine'],
	['GoodMannersOnThePlayground', 'Daily Routine'],
	['GoodMannersWithYourFriends', 'Daily Routine'],
	['GoodMannersWithYourSiblings', 'Daily Routine'],
	['GoodMannersWithYourTeachers', 'Daily Routine'],
	['GoodMannerWithYourParents', 'Daily Routine'],
	['Monday', 'Calendar and Seasons'],
	['Tuesday', 'Calendar and Seasons'],
	['Wednesday', 'Calendar and Seasons'],
	['Thursday', 'Calendar and Seasons'],
	['Friday', 'Calendar and Seasons'],
	['Saturday', 'Calendar and Seasons'],
	['Sunday', 'Calendar and Seasons'],
	['January', 'Calendar and Seasons'],
	['February', 'Calendar and Seasons'],
	['March', 'Calendar and Seasons'],
	['April', 'Calendar and Seasons'],
	['May', 'Calendar and Seasons'],
	['June', 'Calendar and Seasons'],
	['July', 'Calendar and Seasons'],
	['August', 'Calendar and Seasons'],
	['September', 'Calendar and Seasons'],
	['October', 'Calendar and Seasons'],
	['November', 'Calendar and Seasons'],
	['December', 'Calendar and Seasons'],
	['NorthAmerica', 'Geography'],
	['SouthAmerica', 'Geography'],
	['Asia', 'Geography'],
	['Australia', 'Geography'],
	['Africa', 'Geography'],
	['Antarctica', 'Geography'],
	['Europe', 'Geography'],
]);

export const TOPIC_ARRAY = [
	'CanYouSee',
	'FoodChain',
	'Night',
	'SelectPets',
	'Balloon',
	'Home',
	'Diggers',
	'ZoomOut',
	'WhoEatsWhat',
	'BearFamily',
	'SharePizza',
	'MallEvent',
	'TheToysParty',
	'Quiz',
	'PlantsForDinner',
	'StinkyPlants',
	'TomDadColin',
	'MakeGoldfishTank',
	'HomeForOneNight',
	'LetsGo',
	'SilverFoilRocket',
	'DadCanYouDoThis',
	'AFlightToNYC',
	'DinosaurSafari',
	'OurPlanet',
	'ThingsThatSting',
	'AnimalMagic',
	'EveryoneGotWet',
	'Planets',
	'SaveTheWhale',
	'InWoods',
	'DeepSeaCreature',
	'AHouseFitMouse',
	'BigBadBug',
	'DadBirthday',
	'DoNotSneeze',
	'ExploreTheDeep',
	'IncredibleAnimals',
	'LeekHotPot',
	'PaintingTheLoft',
	'SpecialDays',
	'TheFrogPrince',
	'TheScarf',
	'Multiplication',
	'Sorting',
	'Order',
	'Estimate',
	'CountCouple',
	'Pattern',
	'3DShape',
	'2DShape',
	'Fraction',
	'Division',
	'Subtraction',
	'Addition',
	'SpringBook',
	'SummerBook',
	'AutumnBook',
	'WinterBook',
	'SuperShadows',
	'WildWater',
	'AwesomeAir',
	'DiggingDirt',
	'GroovyGravity',
	'RadicalRocks',
	'CrazyClouds',
	'ElectricityIsEverywhere',
	'ExcitingEnergy',
	'MarvelousMotion',
	'MightyMagnets',
	'StupendousSound',
	'FeverPoles',
	'FeverInTheOceans',
	'EarthFever',
	'FeverLand',
	'DinosaursArmoredReptiles',
	'DinosaursCarnivores',
	'DinosaursHerbivores',
	'DinosaursSeaReptiles',
	'DinosaursFlyingRetiples',
	'DinosaursFossilHunters',
	'FoodChainCity',
	'FoodWeb',
	'FoodChainOcean',
	'FoodChainForest',
	'FoodChainDesert',
	'FoodChainGrassland',
	'MaterialWood',
	'MaterialPaper',
	'MaterialMetal',
	'MaterialCloth',
	'MaterialPlastic',
	'MaterialGlass',
	'IncredibleAnimalsSpeeders',
	'IncredibleAnimalsPredators',
	'IncredibleAnimalsMigrators',
	'IncredibleAnimalsHibernators',
	'IncredibleAnimalsBuilders',
	'IncredibleAnimalsCamouflage',
	'EngineeringRoads',
	'EngineeringDams',
	'EngineeringTunnels',
	'EngineeringSkyscrapers',
	'EngineeringCanals',
	'EngineeringBridges',
	'SeaStars',
	'CocoaBean2Chocolate',
	'Cane2Sugar',
	'Milk2Icecream',
	'Oil2Gas',
	'TheElectricCar',
	'Drones',
	'GPS',
	'3DPrinting',
	'Robots',
	'AdSea',
	'AdSpace',
	'AdFlights',
	'AdStorm',
	'AdCave',
	'AdGhosts',
	'AdWarriors',
	'AdPirates',
	'AdDinosaurs',
	'AdMonsters',
	'AdBugs',
	'AdBeasts',
	'InsideBones',
	'InsideBlood',
	'InsideKidneys',
	'InsideMuscles',
	'InsideSkinHairNails',
	'InsideGerms',
	'InsideBrain',
	'InsideEars',
	'InsideEyes',
	'InsideHeart',
	'InsideLungs',
	'InsideStomach',
	'Mass',
	'Electricity',
	'Elements',
	'Light',
	'Magnetism',
	'Temperature',
	'Snowing',
	'Windy',
	'Sunny',
	'Stormy',
	'Cloudy',
	'Raining',
	'Circles',
	'Squares',
	'Triangles',
	'Rectangles',
	'Crescents',
	'RhymesAnimalTales',
	'RhymesBedtime',
	'RhymesCountAlong',
	'RhymesColors',
	'RhymesWeather',
	'GoodMannersAtHome',
	'GoodMannersAtSchool',
	'GoodMannersInARestaurant',
	'GoodMannerInPublic',
	'GoodMannersOnThePhone',
	'GoodMannersOnThePlayground',
	'GoodMannersWithYourFriends',
	'GoodMannersWithYourSiblings',
	'GoodMannersWithYourTeachers',
	'GoodMannerWithYourParents',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday',
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
	'NorthAmerica',
	'SouthAmerica',
	'Asia',
	'Australia',
	'Africa',
	'Antarctica',
	'Europe',
];

export const TOPIC_PAGE_NUM = new Map([
	['CanYouSee', 3],
	['FoodChain', 4],
	['Night', 4],
	['SelectPets', 4],
	['Balloon', 4],
	['Home', 8],
	['Diggers', 7],
	['ZoomOut', 5],
	['WhoEatsWhat', 9],
	['BearFamily', 18],
	['SharePizza', 16],
	['MallEvent', 16],
	['TheToysParty', 9],
	['Quiz', 8],
	['PlantsForDinner', 9],
	['StinkyPlants', 10],
	['TomDadColin', 7],
	['MakeGoldfishTank', 8],
	['HomeForOneNight', 8],
	['LetsGo', 8],
	['SilverFoilRocket', 9],
	['DadCanYouDoThis', 8],
	['AFlightToNYC', 8],
	['DinosaurSafari', 11],
	['OurPlanet', 5],
	['ThingsThatSting', 10],
	['AnimalMagic', 7],
	['EveryoneGotWet', 13],
	['Planets', 5],
	['SaveTheWhale', 8],
	['InWoods', 5],
	['DeepSeaCreature', 6],
	['AHouseFitMouse', 3],
	['BigBadBug', 5],
	['DadBirthday', 5],
	['DoNotSneeze', 11],
	['ExploreTheDeep', 12],
	['IncredibleAnimals', 10],
	['LeekHotPot', 8],
	['PaintingTheLoft', 9],
	['SpecialDays', 8],
	['TheFrogPrince', 11],
	['TheScarf', 13],
	['Multiplication', 10],
	['Sorting', 10],
	['Order', 10],
	['Estimate', 10],
	['CountCouple', 10],
	['Pattern', 10],
	['3DShape', 10],
	['2DShape', 10],
	['Fraction', 10],
	['Division', 10],
	['Subtraction', 10],
	['Addition', 10],
	['SpringBook', 9],
	['SummerBook', 9],
	['AutumnBook', 9],
	['WinterBook', 9],
	['SuperShadows', 13],
	['WildWater', 14],
	['AwesomeAir', 14],
	['DiggingDirt', 11],
	['GroovyGravity', 13],
	['RadicalRocks', 13],
	['CrazyClouds', 14],
	['ElectricityIsEverywhere', 13],
	['ExcitingEnergy', 13],
	['MarvelousMotion', 13],
	['MightyMagnets', 12],
	['StupendousSound', 13],
	['FeverPoles', 12],
	['FeverInTheOceans', 12],
	['EarthFever', 11],
	['FeverLand', 12],
	['DinosaursArmoredReptiles', 13],
	['DinosaursCarnivores', 13],
	['DinosaursHerbivores', 13],
	['DinosaursSeaReptiles', 13],
	['DinosaursFlyingRetiples', 13],
	['DinosaursFossilHunters', 13],
	['FoodChainCity', 12],
	['FoodWeb', 13],
	['FoodChainOcean', 13],
	['FoodChainForest', 13],
	['FoodChainDesert', 13],
	['FoodChainGrassland', 13],
	['MaterialWood', 9],
	['MaterialPaper', 9],
	['MaterialMetal', 9],
	['MaterialCloth', 9],
	['MaterialPlastic', 9],
	['MaterialGlass', 9],
	['IncredibleAnimalsSpeeders', 10],
	['IncredibleAnimalsPredators', 10],
	['IncredibleAnimalsMigrators', 10],
	['IncredibleAnimalsHibernators', 10],
	['IncredibleAnimalsBuilders', 10],
	['IncredibleAnimalsCamouflage', 10],
	['EngineeringRoads', 10],
	['EngineeringDams', 10],
	['EngineeringTunnels', 10],
	['EngineeringSkyscrapers', 10],
	['EngineeringCanals', 10],
	['EngineeringBridges', 10],
	['SeaStars', 10],
	['CocoaBean2Chocolate', 9],
	['Cane2Sugar', 9],
	['Milk2Icecream', 9],
	['Oil2Gas', 9],
	['TheElectricCar', 9],
	['Drones', 9],
	['GPS', 9],
	['3DPrinting', 9],
	['Robots', 9],
	['AdSea', 10],
	['AdSpace', 10],
	['AdFlights', 10],
	['AdStorm', 10],
	['AdCave', 10],
	['AdGhosts', 10],
	['AdWarriors', 10],
	['AdPirates', 10],
	['AdDinosaurs', 10],
	['AdMonsters', 10],
	['AdBugs', 10],
	['AdBeasts', 10],
	['InsideBones', 14],
	['InsideBlood', 14],
	['InsideKidneys', 14],
	['InsideMuscles', 14],
	['InsideSkinHairNails', 14],
	['InsideGerms', 13],
	['InsideBrain', 14],
	['InsideEars', 14],
	['InsideEyes', 14],
	['InsideHeart', 14],
	['InsideLungs', 14],
	['InsideStomach', 14],
	['Mass', 10],
	['Electricity', 10],
	['Elements', 10],
	['Light', 10],
	['Magnetism', 10],
	['Temperature', 10],
	['Snowing', 9],
	['Windy', 9],
	['Sunny', 9],
	['Stormy', 9],
	['Cloudy', 9],
	['Raining', 9],
	['Circles', 10],
	['Squares', 10],
	['Triangles', 10],
	['Rectangles', 10],
	['Crescents', 10],
	['RhymesAnimalTales', 14],
	['RhymesBedtime', 14],
	['RhymesCountAlong', 14],
	['RhymesColors', 14],
	['RhymesWeather', 14],
	['GoodMannersAtHome', 14],
	['GoodMannersAtSchool', 14],
	['GoodMannersInARestaurant', 14],
	['GoodMannerInPublic', 14],
	['GoodMannersOnThePhone', 14],
	['GoodMannersOnThePlayground', 14],
	['GoodMannersWithYourFriends', 13],
	['GoodMannersWithYourSiblings', 14],
	['GoodMannersWithYourTeachers', 14],
	['GoodMannerWithYourParents', 14],
	['Monday', 10],
	['Tuesday', 10],
	['Wednesday', 10],
	['Thursday', 10],
	['Friday', 10],
	['Saturday', 10],
	['Sunday', 10],
	['January', 10],
	['February', 10],
	['March', 10],
	['April', 10],
	['May', 10],
	['June', 10],
	['July', 10],
	['August', 10],
	['September', 10],
	['October', 10],
	['November', 10],
	['December', 10],
	['NorthAmerica', 9],
	['SouthAmerica', 9],
	['Asia', 9],
	['Australia', 9],
	['Africa', 9],
	['Antarctica', 9],
	['Europe', 9],
]);

export const TOPIC_VIDEO_MAP = new Map([]);

export const DEFAULT_TOPIC = 'January';

export const DIR = 'gallery';
