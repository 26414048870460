/** @format */

const The_Powerpuff_Girls = [
	'ThePowerpuffGirls_00.png',
	'ThePowerpuffGirls_01.png',
	'ThePowerpuffGirls_02.png',
	'ThePowerpuffGirls_03.png',
	'ThePowerpuffGirls_04.png',
	'ThePowerpuffGirls_05.png',
];
export default The_Powerpuff_Girls;
