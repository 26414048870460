/** @format */

import Frozen from './Frozen';
import Ultraman from './Ultraman';
import Ironman from './Ironman';
import PeppaPig from './PeppaPig';
import Pokemon from './Pokemon';
import Superman from './Superman';
import CA from './CA';
import PawPatrol from './PawPatrol';
import DinosaurTrain from './DinosaurTrain';
import LandBeforeTime from './LandBeforeTime';
import MyLittlePony from './MyLittlePony';
import Transformer from './Transformer';
import Batman from './Batman';
import ToyStory from './ToyStory';
import ThePowerpuffGirls from './ThePowerpuffGirls';

export const cartoonFileList = {
	Frozen: Frozen,
	Ultraman: Ultraman,
	Ironman: Ironman,
	Peppa_Pig: PeppaPig,
	Pokemon: Pokemon,
	Superman: Superman,
	Captain_America: CA,
	Paw_Patrol: PawPatrol,
	Dinosaur_Train: DinosaurTrain,
	Land_Before_Time: LandBeforeTime,
	My_Little_Pony: MyLittlePony,
	Transformer: Transformer,
	Batman: Batman,
	Toy_Story: ToyStory,
	The_Powerpuff_Girls: ThePowerpuffGirls,
};

export const cartoonDefaultLevel = 'Frozen';
