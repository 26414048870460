/** @format */

export const SLIDE = 'slide';
export const CROSSWORD = 'crossword';
export const GALLERY = 'gallery';
export const BOOK = 'book';
export const DIALOGUE = 'dialogue';
export const MUSIC = 'music';
export const WORDCARD = 'wordCard';
export const MATCH = 'match';
export const MAZE = 'maze';
export const DIFFERENCE = 'difference';
export const COUNTING = 'counting';
export const CROSSWORDIMAGE = 'crosswordImage';
export const SCRAMBLE = 'scarmble';
export const CARTOON = 'cartoon';
export const SCENE = 'scene';
export const ACORN_WORLD = 'acornWorld';

export const CONGRAT_CONFIG = new Map([
	['class', { confetti: 2.8, image: 2.3 }],
	['match', { confetti: 1, image: 0.5 }],
]);

export const CURRENT_THEME = 'acornWorld';

export const COLOR_THEME = {
	space: {
		segment: '#0f4c81',
		menu: '#01182b',
	},
	springFestival: {
		segment: '#800000',
		menu: '#660000',
	},
	easter: {
		segment: '#0abab5',
		menu: '#006a67',
	},
	spring: {
		segment: '#e2b8d9',
		menu: '#b63656',
	},
	motherday: {
		segment: '#9a77cf',
		menu: '#543884',
	},
	childrenDay: {
		segment: '#0993f3',
		menu: '#0763e5',
	},
	ocean: {
		segment: '#003d60',
		menu: '#003d60',
	},
	christmas: {
		segment: '#4f472f',
		menu: '#383323',
	},
	acornWorld: {
		segment: '#BD850B',
		menu: '#90382F',
	},
};
