/** @format */

// convert import value
import Seasons from '../assets/wordCardList/Seasons';
import Christmas from '../assets/wordCardList/Christmas';
import Winter1 from '../assets/wordCardList/Winter1';
import Winter2 from '../assets/wordCardList/Winter2';
import Autumn from '../assets/wordCardList/Autumn';
import SummerHoliday1 from '../assets/wordCardList/SummerHoliday1';
import SummerHoliday2 from '../assets/wordCardList/SummerHoliday2';
import Thanksgiving from '../assets/wordCardList/Thanksgiving';
import NewYear from '../assets/wordCardList/NewYear';
import ChineseNewYear from '../assets/wordCardList/ChineseNewYear';
import Spring from '../assets/wordCardList/Spring';
import Summer from '../assets/wordCardList/Summer';
import Ramadan from '../assets/wordCardList/Ramadan';
import IndependenceDay from '../assets/wordCardList/IndependenceDay';
import Halloween from '../assets/wordCardList/Halloween';
import Easter from '../assets/wordCardList/Easter';
import MothersDay from '../assets/wordCardList/MothersDay';
import ValentinesDay from '../assets/wordCardList/ValentinesDay';
import Sea1 from '../assets/wordCardList/Sea1';
import Sea2 from '../assets/wordCardList/Sea2';
import Sea3 from '../assets/wordCardList/Sea3';
import Animal1 from '../assets/wordCardList/Animal1';
import Animal2 from '../assets/wordCardList/Animal2';
import Animal3 from '../assets/wordCardList/Animal3';
import Animal4 from '../assets/wordCardList/Animal4';
import Animal5 from '../assets/wordCardList/Animal5';
import Dogs from '../assets/wordCardList/Dogs';
import Dinosaur from '../assets/wordCardList/Dinosaur';
import Weather from '../assets/wordCardList/Weather';
import Space from '../assets/wordCardList/Space';
import NaturalDisasters from '../assets/wordCardList/NaturalDisasters';
import ClimateChange from '../assets/wordCardList/ClimateChange';
import Landscape1 from '../assets/wordCardList/Landscape1';
import Landscape2 from '../assets/wordCardList/Landscape2';
import China from '../assets/wordCardList/China';
import SouthAfrica from '../assets/wordCardList/SouthAfrica';
import Australia from '../assets/wordCardList/Australia';
import Italy from '../assets/wordCardList/Italy';
import Japan from '../assets/wordCardList/Japan';
import Russia from '../assets/wordCardList/Russia';
import India from '../assets/wordCardList/India';
import USA from '../assets/wordCardList/USA';
import France from '../assets/wordCardList/France';
import Canada from '../assets/wordCardList/Canada';
import Germany from '../assets/wordCardList/Germany';
import Portugal from '../assets/wordCardList/Portugal';
import Thailand from '../assets/wordCardList/Thailand';
import England from '../assets/wordCardList/England';
import Flags1 from '../assets/wordCardList/Flags1';
import Flags2 from '../assets/wordCardList/Flags2';
import Landmark1 from '../assets/wordCardList/Landmark1';
import Landmark2 from '../assets/wordCardList/Landmark2';
import Landmark3 from '../assets/wordCardList/Landmark3';
import Farm1 from '../assets/wordCardList/Farm1';
import Farm2 from '../assets/wordCardList/Farm2';
import Farm3 from '../assets/wordCardList/Farm3';
import Supermarket from '../assets/wordCardList/Supermarket';
import Bakery from '../assets/wordCardList/Bakery';
import IcecreamShop from '../assets/wordCardList/IcecreamShop';
import Agriculture from '../assets/wordCardList/Agriculture';
import RealEstate from '../assets/wordCardList/RealEstate';
import PostOffice from '../assets/wordCardList/PostOffice';
import Casino from '../assets/wordCardList/Casino';
import Profession1 from '../assets/wordCardList/Profession1';
import Profession2 from '../assets/wordCardList/Profession2';
import Profession3 from '../assets/wordCardList/Profession3';
import Cooking1 from '../assets/wordCardList/Cooking1';
import Cooking2 from '../assets/wordCardList/Cooking2';
import Hobby1 from '../assets/wordCardList/Hobby1';
import Hobby2 from '../assets/wordCardList/Hobby2';
import Hobby3 from '../assets/wordCardList/Hobby3';
import Exercise from '../assets/wordCardList/Exercise';
import Outdoor from '../assets/wordCardList/Outdoor';
import Weekend from '../assets/wordCardList/Weekend';
import Entertainment from '../assets/wordCardList/Entertainment';
import OlympicGames1 from '../assets/wordCardList/OlympicGames1';
import OlympicGames2 from '../assets/wordCardList/OlympicGames2';
import Diving from '../assets/wordCardList/Diving';
import Travel from '../assets/wordCardList/Travel';
import Fruit from '../assets/wordCardList/Fruit';
import Vegetable1 from '../assets/wordCardList/Vegetable1';
import Vegetable2 from '../assets/wordCardList/Vegetable2';
import JapaneseFood from '../assets/wordCardList/JapaneseFood';
import Food1 from '../assets/wordCardList/Food1';
import Food2 from '../assets/wordCardList/Food2';
import Food3 from '../assets/wordCardList/Food3';
import Food4 from '../assets/wordCardList/Food4';
import Fastfood from '../assets/wordCardList/Fastfood';
import Dessert from '../assets/wordCardList/Dessert';
import Seafood from '../assets/wordCardList/Seafood';
import Beverage1 from '../assets/wordCardList/Beverage1';
import Beverage2 from '../assets/wordCardList/Beverage2';
import Vehicles from '../assets/wordCardList/Vehicles';
import CityElements from '../assets/wordCardList/CityElements';
import Transportation from '../assets/wordCardList/Transportation';
import Shops2 from '../assets/wordCardList/Shops2';
import Shops1 from '../assets/wordCardList/Shops1';
import Home1 from '../assets/wordCardList/Home1';
import Home2 from '../assets/wordCardList/Home2';
import Home3 from '../assets/wordCardList/Home3';
import Home4 from '../assets/wordCardList/Home4';
import Toys from '../assets/wordCardList/Toys';
import Clothes1 from '../assets/wordCardList/Clothes1';
import Clothes2 from '../assets/wordCardList/Clothes2';
import DailyRoutine from '../assets/wordCardList/DailyRoutine';
import HealthyLife1 from '../assets/wordCardList/HealthyLife1';
import HealthyLife2 from '../assets/wordCardList/HealthyLife2';
import NewYearResolution from '../assets/wordCardList/NewYearResolution';
import Covid19 from '../assets/wordCardList/Covid19';
import AmusementPark from '../assets/wordCardList/AmusementPark';
import Museum from '../assets/wordCardList/Museum';
import Emotion from '../assets/wordCardList/Emotion';
import Technology1 from '../assets/wordCardList/Technology1';
import Technology2 from '../assets/wordCardList/Technology2';
import HeavyVehicles from '../assets/wordCardList/HeavyVehicles';
import Nuclear from '../assets/wordCardList/Nuclear';
import Economy from '../assets/wordCardList/Economy';
import Finance from '../assets/wordCardList/Finance';
import Computer1 from '../assets/wordCardList/Computer1';
import Computer2 from '../assets/wordCardList/Computer2';
import NASA from '../assets/wordCardList/NASA';
import Auction from '../assets/wordCardList/Auction';
import Medieval from '../assets/wordCardList/Medieval';
import Math1 from '../assets/wordCardList/Math1';
import Math2 from '../assets/wordCardList/Math2';
import MusicInstruments from '../assets/wordCardList/MusicInstruments';
import Chemistry from '../assets/wordCardList/Chemistry';
import Geology from '../assets/wordCardList/Geology';
import Geography from '../assets/wordCardList/Geography';
import Physics from '../assets/wordCardList/Physics';
import Education from '../assets/wordCardList/Education';
import Arts1 from '../assets/wordCardList/Arts1';
import Arts2 from '../assets/wordCardList/Arts2';
import Architecture from '../assets/wordCardList/Architecture';
import HumanBody from '../assets/wordCardList/HumanBody';
import MedicalScience from '../assets/wordCardList/MedicalScience';
import Genres1 from '../assets/wordCardList/Genres1';
import Genres2 from '../assets/wordCardList/Genres2';
import Jewelry from '../assets/wordCardList/Jewelry';
import CuteAnimal from '../assets/wordCardList/CuteAnimal';
import CuteAnimalHead from '../assets/wordCardList/CuteAnimalHead';
import CuteAppliance from '../assets/wordCardList/CuteAppliance';
import FairyTale1 from '../assets/wordCardList/FairyTale1';
import FairyTale2 from '../assets/wordCardList/FairyTale2';
import SuperHero from '../assets/wordCardList/SuperHero';

export const WORDCARD_FILES = {
	Seasons: Seasons,
	Christmas: Christmas,
	Winter1: Winter1,
	Winter2: Winter2,
	Autumn: Autumn,
	SummerHoliday1: SummerHoliday1,
	SummerHoliday2: SummerHoliday2,
	Thanksgiving: Thanksgiving,
	NewYear: NewYear,
	ChineseNewYear: ChineseNewYear,
	Spring: Spring,
	Summer: Summer,
	Ramadan: Ramadan,
	IndependenceDay: IndependenceDay,
	Halloween: Halloween,
	Easter: Easter,
	MothersDay: MothersDay,
	ValentinesDay: ValentinesDay,
	Sea1: Sea1,
	Sea2: Sea2,
	Sea3: Sea3,
	Animal1: Animal1,
	Animal2: Animal2,
	Animal3: Animal3,
	Animal4: Animal4,
	Animal5: Animal5,
	Dogs: Dogs,
	Dinosaur: Dinosaur,
	Weather: Weather,
	Space: Space,
	NaturalDisasters: NaturalDisasters,
	ClimateChange: ClimateChange,
	Landscape1: Landscape1,
	Landscape2: Landscape2,
	China: China,
	SouthAfrica: SouthAfrica,
	Australia: Australia,
	Italy: Italy,
	Japan: Japan,
	Russia: Russia,
	India: India,
	USA: USA,
	France: France,
	Canada: Canada,
	Portugal: Portugal,
	Germany: Germany,
	Thailand: Thailand,
	England: England,
	Flags1: Flags1,
	Flags2: Flags2,
	Landmark1: Landmark1,
	Landmark2: Landmark2,
	Landmark3: Landmark3,
	Farm1: Farm1,
	Farm2: Farm2,
	Farm3: Farm3,
	Supermarket: Supermarket,
	Bakery: Bakery,
	IcecreamShop: IcecreamShop,
	Agriculture: Agriculture,
	RealEstate: RealEstate,
	PostOffice: PostOffice,
	Casino: Casino,
	Profession1: Profession1,
	Profession2: Profession2,
	Profession3: Profession3,
	Cooking1: Cooking1,
	Cooking2: Cooking2,
	Hobby1: Hobby1,
	Hobby2: Hobby2,
	Hobby3: Hobby3,
	Exercise: Exercise,
	Outdoor: Outdoor,
	Weekend: Weekend,
	Entertainment: Entertainment,
	OlympicGames1: OlympicGames1,
	OlympicGames2: OlympicGames2,
	Diving: Diving,
	Travel: Travel,
	Fruit: Fruit,
	Vegetable1: Vegetable1,
	Vegetable2: Vegetable2,
	JapaneseFood: JapaneseFood,
	Food1: Food1,
	Food2: Food2,
	Food3: Food3,
	Food4: Food4,
	Fastfood: Fastfood,
	Dessert: Dessert,
	Seafood: Seafood,
	Beverage1: Beverage1,
	Beverage2: Beverage2,
	Vehicles: Vehicles,
	CityElements: CityElements,
	Transportation: Transportation,
	Shops1: Shops1,
	Shops2: Shops2,
	Home1: Home1,
	Home2: Home2,
	Home3: Home3,
	Home4: Home4,
	Toys: Toys,
	Clothes1: Clothes1,
	Clothes2: Clothes2,
	DailyRoutine: DailyRoutine,
	HealthyLife1: HealthyLife1,
	HealthyLife2: HealthyLife2,
	NewYearResolution: NewYearResolution,
	Covid19: Covid19,
	AmusementPark: AmusementPark,
	Museum: Museum,
	Emotion: Emotion,
	Technology1: Technology1,
	Technology2: Technology2,
	HeavyVehicles: HeavyVehicles,
	Nuclear: Nuclear,
	Economy: Economy,
	Finance: Finance,
	Computer1: Computer1,
	Computer2: Computer2,
	NASA: NASA,
	Auction: Auction,
	Medieval: Medieval,
	Math1: Math1,
	Math2: Math2,
	MusicInstruments: MusicInstruments,
	Chemistry: Chemistry,
	Geology: Geology,
	Geography: Geography,
	Physics: Physics,
	Education: Education,
	Arts1: Arts1,
	Arts2: Arts2,
	Architecture: Architecture,
	HumanBody: HumanBody,
	MedicalScience: MedicalScience,
	Genres1: Genres1,
	Genres2: Genres2,
	Jewelry: Jewelry,
	CuteAnimal: CuteAnimal,
	CuteAnimalHead: CuteAnimalHead,
	CuteAppliance: CuteAppliance,
	FairyTale1: FairyTale1,
	FairyTale2: FairyTale2,
	SuperHero: SuperHero,
};

// WORDCARDs constants
export const WORDCARD_DISPLAY_MAP = new Map([
	['Seasons', 'Four Seasons'],
	['Christmas', 'Christmas'],
	['Winter1', 'Winter 01'],
	['Winter2', 'Winter 02'],
	['Autumn', 'Autumn'],
	['SummerHoliday1', 'Summer Holiday 01'],
	['SummerHoliday2', 'Summer Holiday 02'],
	['Thanksgiving', 'Thanksgiving'],
	['NewYear', 'New Year'],
	['ChineseNewYear', 'Chinese New Year'],
	['Spring', 'Spring'],
	['Summer', 'Summer'],
	['Ramadan', 'Ramadan'],
	['IndependenceDay', 'Independence Day'],
	['Halloween', 'Halloween'],
	['Easter', 'Easter'],
	['MothersDay', 'MothersDay'],
	['ValentinesDay', 'ValentinesDay'],
	['Sea1', 'Sea 01'],
	['Sea2', 'Sea 02'],
	['Sea3', 'Sea 03'],
	['Animal1', 'Animal 01'],
	['Animal2', 'Animal 02'],
	['Animal3', 'Animal 03'],
	['Animal4', 'Animal 04'],
	['Animal5', 'Animal 05'],
	['Dogs', 'Dogs'],
	['Dinosaur', 'Dinosaur'],
	['Weather', 'Weather'],
	['Space', 'Space'],
	['NaturalDisasters', 'Natural Disasters'],
	['ClimateChange', 'Climate Change'],
	['Landscape1', 'Landscape 01'],
	['Landscape2', 'Landscape 02'],
	['China', 'China'],
	['SouthAfrica', 'South Africa'],
	['Australia', 'Australia'],
	['Italy', 'Italy'],
	['Japan', 'Japan'],
	['Russia', 'Russia'],
	['India', 'India'],
	['USA', 'USA'],
	['France', 'France'],
	['Canada', 'Canada'],
	['Portugal', 'Portugal'],
	['Germany', 'Germany'],
	['Thailand', 'Thailand'],
	['England', 'England'],
	['Flags1', 'National Flags 01'],
	['Flags2', 'National Flags 02'],
	['Landmark1', 'Global Landmark 01'],
	['Landmark2', 'Global Landmark 02'],
	['Landmark3', 'Global Landmark 03'],
	['Farm1', 'Farm 01'],
	['Farm2', 'Farm 02'],
	['Farm3', 'Farm 03'],
	['Supermarket', 'Supermarket'],
	['Bakery', 'Bakery'],
	['IcecreamShop', 'Icecream Shop'],
	['Agriculture', 'Agriculture'],
	['RealEstate', 'Real Estate'],
	['PostOffice', 'Post Office'],
	['Casino', 'Casino'],
	['Profession1', 'Profession 01'],
	['Profession2', 'Profession 02'],
	['Profession3', 'Profession 03'],
	['Cooking1', 'Cooking 01'],
	['Cooking2', 'Cooking 02'],
	['Hobby1', 'Hobby 01'],
	['Hobby2', 'Hobby 02'],
	['Hobby3', 'Hobby 03'],
	['Exercise', 'Exercise'],
	['Outdoor', 'Outdoor Activities'],
	['Weekend', 'Weekend Activities'],
	['Entertainment', 'Entertainment'],
	['OlympicGames1', 'Olympic Games 01'],
	['OlympicGames2', 'Olympic Games 02'],
	['Diving', 'Diving'],
	['Travel', 'Travel'],
	['Fruit', 'Fruit'],
	['Vegetable1', 'Vegetable 01'],
	['Vegetable2', 'Vegetable 02'],
	['JapaneseFood', 'Japanese Food'],
	['Food1', 'Food 01'],
	['Food2', 'Food 02'],
	['Food3', 'Food 03'],
	['Food4', 'Food 04'],
	['Fastfood', 'Fast Food'],
	['Dessert', 'Dessert'],
	['Seafood', 'Seafood'],
	['Beverage1', 'Beverage 01'],
	['Beverage2', 'Beverage 02'],
	['Vehicles', 'Vehicles'],
	['CityElements', 'City Elements'],
	['Transportation', 'Transportation'],
	['Shops1', 'Shops 01'],
	['Shops2', 'Shops 02'],
	['Home1', 'Home Living 01'],
	['Home2', 'Home Living 02'],
	['Home3', 'Home Living 03'],
	['Home4', 'Home Living 04'],
	['Toys', 'Toys'],
	['Clothes1', 'Clothes 01'],
	['Clothes2', 'Clothes 02'],
	['DailyRoutine', 'Daily Routine'],
	['HealthyLife1', 'Healthy Life 01'],
	['HealthyLife2', 'Healthy Life 02'],
	['NewYearResolution', 'New Year Resolution'],
	['Covid19', 'COVID-19 Prevention'],
	['AmusementPark', 'Amusement Park'],
	['Museum', 'Museum'],
	['Emotion', 'Emotion'],
	['Technology1', 'Technology 01'],
	['Technology2', 'Technology 02'],
	['HeavyVehicles', 'Heavy Vehicles'],
	['Nuclear', 'Nuclear'],
	['Economy', 'Economy'],
	['Finance', 'Finance'],
	['Computer1', 'Computer 01'],
	['Computer2', 'Computer 02'],
	['NASA', 'NASA'],
	['Auction', 'Auction'],
	['Medieval', 'Medieval'],
	['Math1', 'Math 01'],
	['Math2', 'Math 02'],
	['MusicInstruments', 'Music Instruments'],
	['Chemistry', 'Chemistry'],
	['Geology', 'Geology'],
	['Geography', 'Geography'],
	['Physics', 'Physics'],
	['Education', 'Education'],
	['Arts1', 'Science and Arts 01'],
	['Arts2', 'Science and Arts 02'],
	['Architecture', 'Architecture'],
	['HumanBody', 'Human Body'],
	['MedicalScience', 'Medical Science'],
	['Genres1', 'Book Genres 01'],
	['Genres2', 'Book Genres 02'],
	['Jewelry', 'Jewelry'],
	['CuteAnimal', 'Animals'],
	['CuteAnimalHead', 'Animals Head'],
	['CuteAppliance', 'Home Appliance'],
	['FairyTale1', 'Fairy Tales 01'],
	['FairyTale2', 'Fairy Tales 02'],
	['SuperHero', 'Super Heros'],
]);

export const WORDCARD_GROUP_MAP = new Map([
	['Seasons', 'Season&Holiday'],
	['Christmas', 'Season&Holiday'],
	['Winter1', 'Season&Holiday'],
	['Winter2', 'Season&Holiday'],
	['Autumn', 'Season&Holiday'],
	['SummerHoliday1', 'Season&Holiday'],
	['SummerHoliday2', 'Season&Holiday'],
	['Thanksgiving', 'Season&Holiday'],
	['NewYear', 'Season&Holiday'],
	['ChineseNewYear', 'Season&Holiday'],
	['Spring', 'Season&Holiday'],
	['Summer', 'Season&Holiday'],
	['Ramadan', 'Season&Holiday'],
	['IndependenceDay', 'Season&Holiday'],
	['Halloween', 'Season&Holiday'],
	['Easter', 'Season&Holiday'],
	['MothersDay', 'Season&Holiday'],
	['ValentinesDay', 'Season&Holiday'],
	['Sea1', 'Nature'],
	['Sea2', 'Nature'],
	['Sea3', 'Nature'],
	['Animal1', 'Nature'],
	['Animal2', 'Nature'],
	['Animal3', 'Nature'],
	['Animal4', 'Nature'],
	['Animal5', 'Nature'],
	['Dogs', 'Nature'],
	['Dinosaur', 'Nature'],
	['Weather', 'Nature'],
	['Space', 'Nature'],
	['NaturalDisasters', 'Nature'],
	['ClimateChange', 'Nature'],
	['Landscape1', 'Nature'],
	['Landscape2', 'Nature'],
	['China', 'Country&World'],
	['SouthAfrica', 'Country&World'],
	['Australia', 'Country&World'],
	['Italy', 'Country&World'],
	['Japan', 'Country&World'],
	['Russia', 'Country&World'],
	['India', 'Country&World'],
	['USA', 'Country&World'],
	['France', 'Country&World'],
	['Canada', 'Country&World'],
	['Portugal', 'Country&World'],
	['Germany', 'Country&World'],
	['Thailand', 'Country&World'],
	['England', 'Country&World'],
	['Flags1', 'Country&World'],
	['Flags2', 'Country&World'],
	['Landmark1', 'Country&World'],
	['Landmark2', 'Country&World'],
	['Landmark3', 'Country&World'],
	['Farm1', 'Profession&Workplace'],
	['Farm2', 'Profession&Workplace'],
	['Farm3', 'Profession&Workplace'],
	['Supermarket', 'Profession&Workplace'],
	['Bakery', 'Profession&Workplace'],
	['IcecreamShop', 'Profession&Workplace'],
	['Agriculture', 'Profession&Workplace'],
	['RealEstate', 'Profession&Workplace'],
	['PostOffice', 'Profession&Workplace'],
	['Casino', 'Profession&Workplace'],
	['Profession1', 'Profession&Workplace'],
	['Profession2', 'Profession&Workplace'],
	['Profession3', 'Profession&Workplace'],
	['Cooking1', 'Hobby&Activity'],
	['Cooking2', 'Hobby&Activity'],
	['Hobby1', 'Hobby&Activity'],
	['Hobby2', 'Hobby&Activity'],
	['Hobby3', 'Hobby&Activity'],
	['Exercise', 'Hobby&Activity'],
	['Outdoor', 'Hobby&Activity'],
	['Weekend', 'Hobby&Activity'],
	['Entertainment', 'Hobby&Activity'],
	['OlympicGames1', 'Hobby&Activity'],
	['OlympicGames2', 'Hobby&Activity'],
	['Diving', 'Hobby&Activity'],
	['Travel', 'Hobby&Activity'],
	['Fruit', 'Food&Beverage'],
	['Vegetable1', 'Food&Beverage'],
	['Vegetable2', 'Food&Beverage'],
	['JapaneseFood', 'Food&Beverage'],
	['Food1', 'Food&Beverage'],
	['Food2', 'Food&Beverage'],
	['Food3', 'Food&Beverage'],
	['Food4', 'Food&Beverage'],
	['Fastfood', 'Food&Beverage'],
	['Dessert', 'Food&Beverage'],
	['Seafood', 'Food&Beverage'],
	['Beverage1', 'Food&Beverage'],
	['Beverage2', 'Food&Beverage'],
	['Vehicles', 'City&Life'],
	['CityElements', 'City&Life'],
	['Transportation', 'City&Life'],
	['Shops1', 'City&Life'],
	['Shops2', 'City&Life'],
	['Home1', 'City&Life'],
	['Home2', 'City&Life'],
	['Home3', 'City&Life'],
	['Home4', 'City&Life'],
	['Toys', 'City&Life'],
	['Clothes1', 'City&Life'],
	['Clothes2', 'City&Life'],
	['DailyRoutine', 'City&Life'],
	['HealthyLife1', 'City&Life'],
	['HealthyLife2', 'City&Life'],
	['NewYearResolution', 'City&Life'],
	['Covid19', 'City&Life'],
	['AmusementPark', 'City&Life'],
	['Museum', 'City&Life'],
	['Emotion', 'For Kids'],
	['Technology1', 'Business&Technology'],
	['Technology2', 'Business&Technology'],
	['HeavyVehicles', 'Business&Technology'],
	['Nuclear', 'Business&Technology'],
	['Economy', 'Business&Technology'],
	['Finance', 'Business&Technology'],
	['Computer1', 'Business&Technology'],
	['Computer2', 'Business&Technology'],
	['NASA', 'Business&Technology'],
	['Auction', 'Business&Technology'],
	['Medieval', 'Science&Art'],
	['Math1', 'Science&Art'],
	['Math2', 'Science&Art'],
	['MusicInstruments', 'Science&Art'],
	['Chemistry', 'Science&Art'],
	['Geology', 'Science&Art'],
	['Geography', 'Science&Art'],
	['Physics', 'Science&Art'],
	['Education', 'Science&Art'],
	['Arts1', 'Science&Art'],
	['Arts2', 'Science&Art'],
	['Architecture', 'Science&Art'],
	['HumanBody', 'Science&Art'],
	['MedicalScience', 'Science&Art'],
	['Genres1', 'Science&Art'],
	['Genres2', 'Science&Art'],
	['Jewelry', 'Science&Art'],
	['CuteAnimal', 'For Kids'],
	['CuteAnimalHead', 'For Kids'],
	['CuteAppliance', 'For Kids'],
	['FairyTale1', 'For Kids'],
	['FairyTale2', 'For Kids'],
	['SuperHero', 'For Kids'],
]);

export const WORDCARD_ARRAY = [
	'Seasons',
	'Christmas',
	'Winter1',
	'Winter2',
	'Autumn',
	'SummerHoliday1',
	'SummerHoliday2',
	'Thanksgiving',
	'NewYear',
	'ChineseNewYear',
	'Spring',
	'Summer',
	'Ramadan',
	'IndependenceDay',
	'Halloween',
	'Easter',
	'MothersDay',
	'ValentinesDay',
	'Sea1',
	'Sea2',
	'Sea3',
	'Animal1',
	'Animal2',
	'Animal3',
	'Animal4',
	'Animal5',
	'Dogs',
	'Dinosaur',
	'Weather',
	'Space',
	'NaturalDisasters',
	'ClimateChange',
	'Landscape1',
	'Landscape2',
	'China',
	'SouthAfrica',
	'Australia',
	'Italy',
	'Japan',
	'Russia',
	'India',
	'USA',
	'France',
	'Canada',
	'Portugal',
	'Germany',
	'Thailand',
	'England',
	'Flags1',
	'Flags2',
	'Landmark1',
	'Landmark2',
	'Landmark3',
	'Farm1',
	'Farm2',
	'Farm3',
	'Supermarket',
	'Bakery',
	'IcecreamShop',
	'Agriculture',
	'RealEstate',
	'PostOffice',
	'Casino',
	'Profession1',
	'Profession2',
	'Profession3',
	'Cooking1',
	'Cooking2',
	'Hobby1',
	'Hobby2',
	'Hobby3',
	'Exercise',
	'Outdoor',
	'Weekend',
	'Entertainment',
	'OlympicGames1',
	'OlympicGames2',
	'Diving',
	'Travel',
	'Fruit',
	'Vegetable1',
	'Vegetable2',
	'JapaneseFood',
	'Food1',
	'Food2',
	'Food3',
	'Food4',
	'Fastfood',
	'Dessert',
	'Seafood',
	'Beverage1',
	'Beverage2',
	'Vehicles',
	'CityElements',
	'Transportation',
	'Shops1',
	'Shops2',
	'Home1',
	'Home2',
	'Home3',
	'Home4',
	'Toys',
	'Clothes1',
	'Clothes2',
	'DailyRoutine',
	'HealthyLife1',
	'HealthyLife2',
	'NewYearResolution',
	'Covid19',
	'AmusementPark',
	'Museum',
	'Emotion',
	'Technology1',
	'Technology2',
	'HeavyVehicles',
	'Nuclear',
	'Economy',
	'Finance',
	'Computer1',
	'Computer2',
	'NASA',
	'Auction',
	'Medieval',
	'Math1',
	'Math2',
	'MusicInstruments',
	'Chemistry',
	'Geology',
	'Geography',
	'Physics',
	'Education',
	'Arts1',
	'Arts2',
	'Architecture',
	'HumanBody',
	'MedicalScience',
	'Genres1',
	'Genres2',
	'Jewelry',
	'CuteAnimal',
	'CuteAnimalHead',
	'CuteAppliance',
	'FairyTale1',
	'FairyTale2',
	'SuperHero',
];

export const DEFAULT_WORDCARD = 'NewYearResolution';

export const DIR = 'wordCard';
