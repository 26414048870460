/** @format */

import slideStore from '../stores/slideStore';
import collectWordCardUtils from './collectWordCardUtils';
import _ from 'lodash';
import {
	SLIDE_PAGE_NUM,
	SLIDE_ARRAY,
	SLIDE_LEVEL_MAP,
	SLIDE_DISPLAY_MAP,
	DEFAULT_BOOK,
	DIR,
	SLIDE_MATCH_GAME_MAP,
} from '../constants/classSlideConfigs';

const initializeData = (isLoggedIn) => {
	slideStore.setLevel(SLIDE_LEVEL_MAP.get(DEFAULT_BOOK));
	slideStore.setBook(DEFAULT_BOOK);
	slideStore.setDefaultSelectOptions(
		getDropdownOption([DEFAULT_BOOK], SLIDE_DISPLAY_MAP)[0]
	);
	slideStore.setDefaultLevelOptions(
		getLevelOption([DEFAULT_BOOK], SLIDE_LEVEL_MAP)[0]
	);

	let levelOptions, dropdownOptions;
	if (isLoggedIn) {
		levelOptions = getLevelOption(SLIDE_ARRAY, SLIDE_LEVEL_MAP);
		const slideArrayByLevel = SLIDE_ARRAY.filter(
			(book) =>
				SLIDE_LEVEL_MAP.get(book) === slideStore.defaultLevelOption.value ||
				Number(SLIDE_LEVEL_MAP.get(book)) ===
					Number(slideStore.defaultLevelOption.value) + 1
		);
		dropdownOptions = getDropdownOption(slideArrayByLevel, SLIDE_DISPLAY_MAP);
	} else {
		levelOptions = getLevelOption([DEFAULT_BOOK], SLIDE_LEVEL_MAP);
		dropdownOptions = getDropdownOption([DEFAULT_BOOK], SLIDE_DISPLAY_MAP);
	}
	slideStore.setLevelOptions(levelOptions);
	slideStore.setSelectOptions(dropdownOptions);

	const slideArray = getComponentArray(
		DEFAULT_BOOK,
		SLIDE_PAGE_NUM.get(DEFAULT_BOOK),
		DIR
	);
	slideStore.setSlideArray(slideArray);
	slideStore.setImageNameList(
		collectWordCardUtils.createWordCardComponent(
			SLIDE_MATCH_GAME_MAP.get(DEFAULT_BOOK)
		)
	);
};

const createPhotoNameArray = (name, pageNum, extension) => {
	const imageType = !!extension ? extension : 'png';
	const array = [];
	for (let i = 0; i <= pageNum; i++) {
		let fileName = `${name}_`;
		if (i < 10) {
			fileName += `0${i}.${imageType}`;
		} else {
			fileName += `${i}.${imageType}`;
		}
		array.push(fileName);
	}
	return array;
};

const getDropdownOption = (bookArray, displayMap) => {
	const options = [];
	for (let i = 0; i < bookArray.length; i++) {
		options.push({
			key: Math.floor(Math.random() * 100000000),
			text: displayMap.get(bookArray[i]),
			value: { i },
			book: bookArray[i],
		});
	}
	const sortedOptions = _.sortBy(options, ['text']);
	for (let i = 0; i < bookArray.length; i++) {
		sortedOptions[i].value = { i };
	}
	return sortedOptions;
};

const getLevelOption = (bookArray, groupMap) => {
	const levelArray = _.chain(bookArray)
		.map((b) => groupMap.get(b))
		.uniq()
		.value();
	levelArray.sort();
	const options = [];
	for (let i = 0; i < levelArray.length; i++) {
		options.push({
			key: Math.floor(Math.random() * 100000000),
			text: `Age ${levelArray[i]}`,
			value: levelArray[i],
		});
	}
	return options;
};

const getComponentArray = (name, num, dir, imageType) => {
	const bookName = name;
	const slideNameArray = createPhotoNameArray(bookName, num, imageType);
	let componentArray = [];
	for (let i = 0; i < num; i++) {
		const fileName = slideNameArray[i];
		componentArray.push({
			original: require(`../assets/${dir}/${bookName}/${fileName}`).default,
			thumbnail: require(`../assets/${dir}/${bookName}/${fileName}`).default,
		});
	}
	return componentArray;
};

const findHeaderByLevel = (level, array, groupMap) => {
	return array.filter(
		(header) =>
			groupMap.get(header) === level ||
			Number(groupMap.get(header)) === Number(level) + 1
	);
};

const getSearchOptions = () => {
	const options = [];
	SLIDE_DISPLAY_MAP.forEach((value, key) => {
		options.push({
			title: value,
			description: `Age: ${SLIDE_LEVEL_MAP.get(key)}`,
			key: key,
		});
	});
	return options;
};

export default {
	createPhotoNameArray,
	getComponentArray,
	initializeData,
	getDropdownOption,
	getLevelOption,
	findHeaderByLevel,
	getSearchOptions,
};
