/** @format */

import {
	DEFAULT_GAME,
	MAZE_ARRAY,
	MAZE_DISPLAY_MAP,
	MAZE_PAGE_NUM,
	MAZE_DIR,
	CROSSWORD_ARRAY,
	CROSSWORD_DISPLAY_MAP,
	CROSSWORD_PAGE_NUM,
	CROSSWORD_DIR,
	COUNTING_ARRAY,
	COUNTING_DISPLAY_MAP,
	COUNTING_PAGE_NUM,
	COUNTING_DIR,
	DIFFERENCE_ARRAY,
	DIFFERENCE_DISPLAY_MAP,
	DIFFERENCE_PAGE_NUM,
	DIFFERENCE_DIR,
} from '../constants/imageGameConfigs';
import collectSlideUtils from './collectSlideUtils';
import slideStore from '../stores/slideStore';

const initializeData = (isLoggedIn) => {
	slideStore.setDefaultMazeOption(
		getDropdownOption(MAZE_DISPLAY_MAP, MAZE_ARRAY, DEFAULT_GAME)
	);
	slideStore.setDefaultCrosswordImageOption(
		getDropdownOption(CROSSWORD_DISPLAY_MAP, CROSSWORD_ARRAY, DEFAULT_GAME)
	);
	slideStore.setDefaultCountingOption(
		getDropdownOption(COUNTING_DISPLAY_MAP, COUNTING_ARRAY, DEFAULT_GAME)
	);
	slideStore.setDefaultDifferenceOption(
		getDropdownOption(DIFFERENCE_DISPLAY_MAP, DIFFERENCE_ARRAY, DEFAULT_GAME)
	);

	if (isLoggedIn) {
		slideStore.setMazeSelectOptions(
			getDropdownOption(MAZE_DISPLAY_MAP, MAZE_ARRAY)
		);
		slideStore.setCrosswordImageSelectOptions(
			getDropdownOption(CROSSWORD_DISPLAY_MAP, CROSSWORD_ARRAY)
		);
		slideStore.setCountingSelectOptions(
			getDropdownOption(COUNTING_DISPLAY_MAP, COUNTING_ARRAY)
		);
		slideStore.setDifferenceSelectOptions(
			getDropdownOption(DIFFERENCE_DISPLAY_MAP, DIFFERENCE_ARRAY)
		);
	} else {
		slideStore.setMazeSelectOptions(slideStore.defaultMazeOption);
		slideStore.setCrosswordImageSelectOptions(
			slideStore.defaultCrosswordImageOption
		);
		slideStore.setCountingSelectOptions(slideStore.defaultCountingOption);
		slideStore.setDifferenceSelectOptions(slideStore.defaultDifferenceOption);
	}
	slideStore.setMazeGame(DEFAULT_GAME);
	slideStore.setCrosswordImageGame(DEFAULT_GAME);
	slideStore.setCountingGame(DEFAULT_GAME);
	slideStore.setDifferenceGame(DEFAULT_GAME);

	slideStore.setMazeImageGameArray(
		collectSlideUtils.getComponentArray(
			DEFAULT_GAME,
			MAZE_PAGE_NUM.get(DEFAULT_GAME),
			MAZE_DIR
		)
	);
	slideStore.setCrosswordImageGameArray(
		collectSlideUtils.getComponentArray(
			DEFAULT_GAME,
			CROSSWORD_PAGE_NUM.get(DEFAULT_GAME),
			CROSSWORD_DIR
		)
	);
	slideStore.setCountingImageGameArray(
		collectSlideUtils.getComponentArray(
			DEFAULT_GAME,
			COUNTING_PAGE_NUM.get(DEFAULT_GAME),
			COUNTING_DIR
		)
	);
	slideStore.setDifferenceImageGameArray(
		collectSlideUtils.getComponentArray(
			DEFAULT_GAME,
			DIFFERENCE_PAGE_NUM.get(DEFAULT_GAME),
			DIFFERENCE_DIR
		)
	);
};

const getDropdownOption = (displayMap, contentArray, defaultGame) => {
	if (defaultGame) {
		return [
			{
				key: Math.floor(Math.random() * 100000000),
				text: displayMap.get(defaultGame),
				value: 0,
				game: defaultGame,
			},
		];
	}
	const options = [];
	for (let i = 0; i < contentArray.length; i++) {
		options.push({
			key: Math.floor(Math.random() * 100000000),
			text: displayMap.get(contentArray[i]),
			value: { i },
			game: contentArray[i],
		});
	}
	return options;
};

export default {
	getDropdownOption,
	initializeData,
};
