/** @format */

// DIALOGUEs constants
export const CARTOON_FOLDER_MAP = new Map([
	['Frozen', 'Frozen'],
	['Ultraman', 'Ultraman'],
	['Ironman', 'Ironman'],
	['Peppa_Pig', 'PeppaPig'],
	['Pokemon', 'Pokemon'],
	['Superman', 'Superman'],
	['Captain_America', 'CA'],
	['Paw_Patrol', 'PawPatrol'],
	['Dinosaur_Train', 'DinosaurTrain'],
	['Land_Before_Time', 'LandBeforeTime'],
	['My_Little_Pony', 'MyLittlePony'],
	['Transformer', 'Transformer'],
	['Batman', 'Batman'],
	['Toy_Story', 'ToyStory'],
	['The_Powerpuff_Girls', 'ThePowerpuffGirls'],
]);

export const CARTOON_VIDEO_MAP = new Map([
	['Frozen', 'L0MK7qz13bU'],
	['Ultraman', 'szXelr31Ddk'],
	['Ironman', 'FdWJ-Njeaig'],
	['PeppaPig', 'Zh_I3uJqhrk'],
	['Pokemon', 'DPE3J9ama_E'],
	['Superman', 'X5xvlXV9crE'],
	['CA', 'G9LC9P2OKVE'],
	['PawPatrol', '1UdI_eoDPKQ'],
	['DinosaurTrain', 'g1O_pZClyrc'],
	['LandBeforeTime', 'CgfzXFVOcrM'],
	['MyLittlePony', '0rgBWhNIDOE'],
	['Transformer', '4DwCDhM3uYM'],
	['Batman', 'X7SiuQxhAjg'],
	['ToyStory', 'nMN4JZ8crVY'],
	['ThePowerpuffGirls', 'qaZnfMaraLs'],
]);

export const DEFAULT_ARRAY = [
	{
		original: require(`../assets/cartoon/Frozen/Frozen_00.png`).default,
		thumbnail: require(`../assets/cartoon/Frozen/Frozen_00.png`).default,
	},
	{
		original: require(`../assets/cartoon/Frozen/Frozen_01.png`).default,
		thumbnail: require(`../assets/cartoon/Frozen/Frozen_01.png`).default,
	},
	{
		original: require(`../assets/cartoon/Frozen/Frozen_02.png`).default,
		thumbnail: require(`../assets/cartoon/Frozen/Frozen_02.png`).default,
	},
	{
		original: require(`../assets/cartoon/Frozen/Frozen_03.png`).default,
		thumbnail: require(`../assets/cartoon/Frozen/Frozen_03.png`).default,
	},
	{
		original: require(`../assets/cartoon/Frozen/Frozen_04.png`).default,
		thumbnail: require(`../assets/cartoon/Frozen/Frozen_04.png`).default,
	},
	{
		original: require(`../assets/cartoon/Frozen/Frozen_05.png`).default,
		thumbnail: require(`../assets/cartoon/Frozen/Frozen_05.png`).default,
	},
];
