/** @format */

const Letters05 = [
	'33148.jpg',
	'35591.jpg',
	'35891.jpg',
	'40167.jpg',
	'40169.jpg',
	'40176.jpg',
	'40187.jpg',
	'40189.jpg',
	'40208.jpg',
	'40214.jpg',
	'40257.jpg',
	'40259.jpg',
	'40260.jpg',
	'40263.jpg',
	'40268.jpg',
	'40272.jpg',
	'40280.jpg',
	'40288.jpg',
	'40293.jpg',
	'40309.jpg',
	'40311.jpg',
	'40317.jpg',
];
export default Letters05;
