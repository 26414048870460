/** @format */

import galleryStore from '../stores/galleryStore';
import collectSlideUtils from './collectSlideUtils';
import _ from 'lodash';
import {
	TOPIC_PAGE_NUM,
	TOPIC_ARRAY,
	TOPIC_GROUP_MAP,
	TOPIC_DISPLAY_MAP,
	DEFAULT_TOPIC,
	DIR,
} from '../constants/gallerySlideConfigs';

const initializeData = (isLoggedIn) => {
	galleryStore.setLevel(TOPIC_GROUP_MAP.get(DEFAULT_TOPIC));
	galleryStore.setBook(DEFAULT_TOPIC);
	galleryStore.setDefaultSelectOptions(
		collectSlideUtils.getDropdownOption([DEFAULT_TOPIC], TOPIC_DISPLAY_MAP)[0]
	);
	galleryStore.setDefaultLevelOptions(
		getLevelOption([DEFAULT_TOPIC], TOPIC_GROUP_MAP)[0]
	);

	let levelOptions, dropdownOptions;
	if (isLoggedIn) {
		levelOptions = getLevelOption(TOPIC_ARRAY, TOPIC_GROUP_MAP);
		const slideArrayByLevel = TOPIC_ARRAY.filter(
			(book) =>
				TOPIC_GROUP_MAP.get(book) === galleryStore.defaultLevelOption.value
		);
		dropdownOptions = collectSlideUtils.getDropdownOption(
			slideArrayByLevel,
			TOPIC_DISPLAY_MAP
		);
	} else {
		levelOptions = getLevelOption([DEFAULT_TOPIC], TOPIC_GROUP_MAP);
		dropdownOptions = collectSlideUtils.getDropdownOption(
			[DEFAULT_TOPIC],
			TOPIC_DISPLAY_MAP
		);
	}

	galleryStore.setLevelOptions(levelOptions);
	galleryStore.setSelectOptions(dropdownOptions);

	const slideArray = collectSlideUtils.getComponentArray(
		DEFAULT_TOPIC,
		TOPIC_PAGE_NUM.get(DEFAULT_TOPIC),
		DIR
	);
	galleryStore.setSlideArray(slideArray);
};

const getLevelOption = (bookArray, groupMap) => {
	const levelArray = _.chain(bookArray)
		.map((b) => groupMap.get(b))
		.uniq()
		.value();
	levelArray.sort();
	const options = [];
	for (let i = 0; i < levelArray.length; i++) {
		options.push({
			key: Math.floor(Math.random() * 100000000),
			text: levelArray[i],
			value: levelArray[i],
		});
	}
	return options;
};

const getSearchOptions = () => {
	const options = [];
	TOPIC_DISPLAY_MAP.forEach((value, key) => {
		options.push({
			title: value,
			description: `Topic: ${TOPIC_GROUP_MAP.get(key)}`,
			key: key,
		});
	});
	return options;
};

export default {
	initializeData,
	getSearchOptions,
};
