/** @format */

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dropdown, Grid, Divider, Search } from 'semantic-ui-react';
import SearchBar from './SearchBar';
import collectSlideUtils from '../utils/collectSlideUtils';
import collectWordCardUtils from '../utils/collectWordCardUtils';
import {
	SLIDE_PAGE_NUM,
	SLIDE_DISPLAY_MAP,
	SLIDE_ARRAY,
	SLIDE_LEVEL_MAP,
	DIR,
	SLIDE_MATCH_GAME_MAP,
} from '../constants/classSlideConfigs';

const SlideButton = inject('slideStore')(
	observer(
		class SlideButton extends Component {
			chooseBook = (v) => {
				const newBook = this.props.slideStore.selectOptions[v.value.i];
				this.props.slideStore.setBook(newBook.book);
				const slideArray = collectSlideUtils.getComponentArray(
					newBook.book,
					SLIDE_PAGE_NUM.get(newBook.book),
					DIR
				);
				this.props.slideStore.setSlideArray(slideArray);
				if (SLIDE_MATCH_GAME_MAP.has(newBook.book)) {
					this.props.slideStore.setImageNameList(
						collectWordCardUtils.createWordCardComponent(
							SLIDE_MATCH_GAME_MAP.get(newBook.book)
						)
					);
				}
			};

			chooseLevel = (v) => {
				this.props.slideStore.setLevel(v.value);
				const newHeaderArray = collectSlideUtils.findHeaderByLevel(
					v.value,
					SLIDE_ARRAY,
					SLIDE_LEVEL_MAP
				);
				const selectOptions = collectSlideUtils.getDropdownOption(
					newHeaderArray,
					SLIDE_DISPLAY_MAP
				);
				this.props.slideStore.setSelectOptions(selectOptions);
			};

			onSelectSearchResult = (key) => {
				const newLevel = SLIDE_LEVEL_MAP.get(key);
				this.chooseLevel({ value: newLevel });
				this.props.slideStore.setBook(key);
				const slideArray = collectSlideUtils.getComponentArray(
					key,
					SLIDE_PAGE_NUM.get(key),
					DIR
				);
				this.props.slideStore.setSlideArray(slideArray);
				if (SLIDE_MATCH_GAME_MAP.has(key)) {
					this.props.slideStore.setImageNameList(
						collectWordCardUtils.createWordCardComponent(
							SLIDE_MATCH_GAME_MAP.get(key)
						)
					);
				}
			};

			render() {
				return (
					<div>
						<Grid>
							<Grid.Column width={6}>
								<Dropdown
									text='Step1: Select a Level'
									className='button icon'
									floating
									fluid
									defaultValue={this.props.slideStore.defaultLevelOption}
									options={this.props.slideStore.levelOptions}
									onChange={(e, v) => this.chooseLevel(v)}
									labeled
									icon='folder open'
									disabled={!this.props.slideStore.user}
								/>
								<div style={{ paddingTop: '10px' }}>
									<Divider horizontal inverted>
										Age {this.props.slideStore.level}
									</Divider>
								</div>
							</Grid.Column>
							<Grid.Column width={6}>
								<Dropdown
									text='Step2: Select a Class'
									className='button icon'
									floating
									fluid
									defaultValue={this.props.slideStore.defaultSelectOption}
									options={this.props.slideStore.selectOptions}
									onChange={(e, v) => this.chooseBook(v)}
									labeled
									icon='book'
									disabled={!this.props.slideStore.user}
								/>
								<div style={{ paddingTop: '10px' }}>
									<Divider horizontal inverted>
										{' '}
										{SLIDE_DISPLAY_MAP.get(this.props.slideStore.book)}
									</Divider>
								</div>
							</Grid.Column>
							<Grid.Column width={4}>
								<SearchBar
									logged={!!this.props.slideStore.user}
									onSelect={this.onSelectSearchResult}
									options={collectSlideUtils.getSearchOptions()}
								/>
							</Grid.Column>
						</Grid>
					</div>
				);
			}
		}
	)
);

export default SlideButton;
