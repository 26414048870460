/** @format */

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dropdown, Grid, Divider } from 'semantic-ui-react';
import {
	MAZE_DISPLAY_MAP,
	MAZE_PAGE_NUM,
	MAZE_DIR,
	CROSSWORD_DISPLAY_MAP,
	CROSSWORD_PAGE_NUM,
	CROSSWORD_DIR,
	COUNTING_DISPLAY_MAP,
	COUNTING_PAGE_NUM,
	COUNTING_DIR,
	DIFFERENCE_DISPLAY_MAP,
	DIFFERENCE_PAGE_NUM,
	DIFFERENCE_DIR,
} from '../../constants/imageGameConfigs';
import {
	MAZE,
	CROSSWORDIMAGE,
	COUNTING,
	DIFFERENCE,
} from '../../constants/constants';
import collectSlideUtils from '../../utils/collectSlideUtils';

const ImageGameButton = inject('slideStore')(
	observer(
		class ImageGameButton extends Component {
			chooseMazeGame = (v) => {
				const newGame = this.props.slideStore.mazeSelectOptions[v.value.i].game;
				this.props.slideStore.setMazeGame(newGame);
				this.props.slideStore.setMazeImageGameArray(
					collectSlideUtils.getComponentArray(
						newGame,
						MAZE_PAGE_NUM.get(newGame),
						MAZE_DIR
					)
				);
			};

			chooseCrosswordGame = (v) => {
				const newGame = this.props.slideStore.crosswordImageSelectOptions[
					v.value.i
				].game;
				this.props.slideStore.setCrosswordImageGame(newGame);
				this.props.slideStore.setCrosswordImageGameArray(
					collectSlideUtils.getComponentArray(
						newGame,
						CROSSWORD_PAGE_NUM.get(newGame),
						CROSSWORD_DIR
					)
				);
			};

			chooseCountingGame = (v) => {
				const newGame = this.props.slideStore.countingSelectOptions[v.value.i]
					.game;
				this.props.slideStore.setCountingGame(newGame);
				this.props.slideStore.setCountingImageGameArray(
					collectSlideUtils.getComponentArray(
						newGame,
						COUNTING_PAGE_NUM.get(newGame),
						COUNTING_DIR
					)
				);
			};

			chooseDifferenceGame = (v) => {
				const newGame = this.props.slideStore.differenceSelectOptions[v.value.i]
					.game;
				this.props.slideStore.setDifferenceGame(newGame);
				this.props.slideStore.setDifferenceImageGameArray(
					collectSlideUtils.getComponentArray(
						newGame,
						DIFFERENCE_PAGE_NUM.get(newGame),
						DIFFERENCE_DIR
					)
				);
			};

			render() {
				if (this.props.slideStore.content === MAZE) {
					return (
						<div>
							<Grid>
								<Grid.Column textAlign='right' width={8}>
									<Dropdown
										text='Select a Game'
										className='button icon'
										floating
										defaultValue={this.props.slideStore.defaultMazeOption.value}
										options={this.props.slideStore.mazeSelectOptions}
										onChange={(e, v) => this.chooseMazeGame(v)}
										fluid
										labeled
										disabled={!this.props.slideStore.user}
										icon='gamepad'
									/>
								</Grid.Column>
							</Grid>
							<div style={{ paddingTop: '10px' }}>
								<Divider horizontal style={{ color: '#ffffff' }}>
									{MAZE_DISPLAY_MAP.get(this.props.slideStore.mazeGame)}
								</Divider>
							</div>
						</div>
					);
				} else if (this.props.slideStore.content === CROSSWORDIMAGE) {
					return (
						<div>
							<Grid>
								<Grid.Column textAlign='right' width={8}>
									<Dropdown
										text='Select a Game'
										className='button icon'
										floating
										defaultValue={
											this.props.slideStore.defaultCrosswordOption.value
										}
										options={this.props.slideStore.crosswordImageSelectOptions}
										onChange={(e, v) => this.chooseCrosswordGame(v)}
										fluid
										labeled
										disabled={!this.props.slideStore.user}
										icon='gamepad'
									/>
								</Grid.Column>
							</Grid>
							<div style={{ paddingTop: '10px' }}>
								<Divider horizontal style={{ color: '#ffffff' }}>
									{CROSSWORD_DISPLAY_MAP.get(
										this.props.slideStore.crosswordImageGame
									)}
								</Divider>
							</div>
						</div>
					);
				} else if (this.props.slideStore.content === COUNTING) {
					return (
						<div>
							<Grid>
								<Grid.Column textAlign='right' width={8}>
									<Dropdown
										text='Select a Game'
										className='button icon'
										floating
										defaultValue={
											this.props.slideStore.defaultCountingOption.value
										}
										options={this.props.slideStore.countingSelectOptions}
										onChange={(e, v) => this.chooseCountingGame(v)}
										fluid
										labeled
										disabled={!this.props.slideStore.user}
										icon='gamepad'
									/>
								</Grid.Column>
							</Grid>
							<div style={{ paddingTop: '10px' }}>
								<Divider horizontal style={{ color: '#ffffff' }}>
									{COUNTING_DISPLAY_MAP.get(this.props.slideStore.countingGame)}
								</Divider>
							</div>
						</div>
					);
				} else if (this.props.slideStore.content === DIFFERENCE) {
					return (
						<div>
							<Grid>
								<Grid.Column textAlign='right' width={8}>
									<Dropdown
										text='Select a Game'
										className='button icon'
										floating
										defaultValue={
											this.props.slideStore.defaultDifferenceOption.value
										}
										options={this.props.slideStore.differenceSelectOptions}
										onChange={(e, v) => this.chooseDifferenceGame(v)}
										fluid
										labeled
										disabled={!this.props.slideStore.user}
										icon='gamepad'
									/>
								</Grid.Column>
							</Grid>
							<div style={{ paddingTop: '10px' }}>
								<Divider horizontal style={{ color: '#ffffff' }}>
									{DIFFERENCE_DISPLAY_MAP.get(
										this.props.slideStore.differenceGame
									)}
								</Divider>
							</div>
						</div>
					);
				}
			}
		}
	)
);

export default ImageGameButton;
