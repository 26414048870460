/** @format */

// MUSICs constants
export const MUSIC_DISPLAY_MAP = new Map([
	['FrozenMusic1', 'Do You Want to Build a Snowman?'],
	['BusSongs1', 'The Bus Wheels Go Round and Round.'],
	['SpiderSong', 'Spider Song'],
	['DinosaursSong', 'We Are The Dinosaurs.'],
	['JingleBells', 'Jingle Bells'],
]);

export const MUSIC_GROUP_MAP = new Map([
	['FrozenMusic1', 'Frozen'],
	['BusSongs1', 'Kids'],
	['SpiderSong', 'Kids'],
	['DinosaursSong', 'Kids'],
	['JingleBells', 'Christmas'],
]);

export const MUSIC_ARRAY = [
	'FrozenMusic1',
	'BusSongs1',
	'SpiderSong',
	'DinosaursSong',
	'JingleBells',
];

export const MUSIC_VIDEO_MAP = new Map([
	['FrozenMusic1', 'TeQ_TTyLGMs'],
	['BusSongs1', 'ki_R_4FcFK0'],
	['SpiderSong', 'w_lCi8U49mY'],
	['DinosaursSong', 'TnPAWLKUd1c'],
	['JingleBells', '4YBGRGBj7_w'],
]);

export const MUSIC_LYRIC = new Set(['JingleBells']);

export const DEFAULT_MUSIC = 'JingleBells';
