/** @format */

import React, { useRef } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Grid } from 'semantic-ui-react';
import WordCardFlipCard from './WordCardFlipCard';

const WordCardView = inject('wordCardStore')(
	observer(function WordCardView(props) {
		const refs = useRef([]);
		refs.current.forEach((child) => {
			if (child) {
				child.validate();
			}
		});

		refs.current = [];
		const cardComponents = [];
		let rowComponent = [];
		let index = 0;
		for (let r = 0; r < 4; r++) {
			rowComponent = [];
			for (let c = 0; c < 4; c++) {
				index = r * 4 + c;
				rowComponent.push(
					<Grid.Column width={4}>
						<WordCardFlipCard
							file={props.wordCardStore.imageNameList[index]}
							topic={props.wordCardStore.book}
							ref={(ins) => {
								if (ins) {
									ins.key = index;
									refs.current.push(ins);
								}
							}}
						/>
					</Grid.Column>
				);
			}
			cardComponents.push(<Grid.Row>{rowComponent}</Grid.Row>);
		}

		return (
			<div>
				<Grid>{cardComponents}</Grid>
				<div style={{ paddingTop: '20px' }}>
					<Button
						content='Reset'
						onClick={() => {
							refs.current.forEach((child) => {
								if (child) {
									child.validate();
								}
							});
						}}
						color='pink'
					/>
				</div>
			</div>
		);
	})
);

export default WordCardView;
