/** @format */

const quiz1 = {
	across: {
		2: {
			clue: 'The opposite of strong',
			answer: 'WEAK',
			row: 0,
			col: 3,
		},
		4: {
			clue: 'A sky color',
			answer: 'BLUE',
			row: 1,
			col: 0,
		},
		5: {
			clue: 'What does build webs to catch insects?',
			answer: 'SPIDER',
			row: 3,
			col: 0,
		},
		6: {
			clue: 'A bird builds this.',
			answer: 'NEST',
			row: 5,
			col: 3,
		},
		7: {
			clue: 'A green animal that croaks.',
			answer: 'FROG',
			row: 6,
			col: 0,
		},
	},
	down: {
		1: {
			clue: 'A soft, comfortable kind of shoe worn indoors',
			answer: 'SLIPPERS',
			row: 0,
			col: 1,
		},
		2: {
			clue: 'The time when a man and woman get married',
			answer: 'WEDDING',
			row: 0,
			col: 3,
		},
		4: {
			clue: "Write someone's name and ___ on an envelope",
			answer: 'ADDRESS',
			row: 0,
			col: 5,
		},
	},
};

const quiz2 = {
	across: {
		1: {
			clue: 'I got a bowl ____ cereal.',
			answer: 'OF',
			row: 0,
			col: 1,
		},
		3: {
			clue: 'How old ____ you?',
			answer: 'ARE',
			row: 2,
			col: 1,
		},
		4: {
			clue: 'Stay ____ your seat.',
			answer: 'ON',
			row: 3,
			col: 0,
		},
	},
	down: {
		2: {
			clue: 'I made this ____ you.',
			answer: 'FOR',
			row: 0,
			col: 2,
		},
		3: {
			clue: 'Blue ____ green are my favorite colors.',
			answer: 'AND',
			row: 2,
			col: 1,
		},
	},
};

const winter = {
	across: {
		1: {
			clue: 'Fall from sky in winter.',
			answer: 'SNOW',
			row: 0,
			col: 4,
		},
		3: {
			clue: 'Last month of year',
			answer: 'DECEMBER',
			row: 4,
			col: 0,
		},
		4: {
			clue: 'The coldest season',
			answer: 'WINTER',
			row: 6,
			col: 2,
		},
	},
	down: {
		2: {
			clue: 'Frozen water',
			answer: 'ICE',
			row: 2,
			col: 1,
		},
		1: {
			clue: 'Guy built of snow',
			answer: 'SNOWMAN',
			row: 0,
			col: 4,
		},
	},
};

const mother = {
	across: {
		2: {
			clue: 'A short name for Mother.',
			answer: 'MOM',
			row: 1,
			col: 5,
		},
		5: {
			clue: 'A present',
			answer: 'GIFT',
			row: 3,
			col: 2,
		},
		7: {
			clue: 'How about some ____ and kisses.',
			answer: 'HUGS',
			row: 4,
			col: 5,
		},
		8: {
			clue: 'The season is ____',
			answer: 'SPRING',
			row: 5,
			col: 8,
		},
		9: {
			clue: 'She might plant these in her garden.',
			answer: 'FLOWERS',
			row: 6,
			col: 0,
		},
	},
	down: {
		1: {
			clue: 'We might go ____ Grandma.',
			answer: 'VISIT',
			row: 0,
			col: 3,
		},
		2: {
			clue: 'A formal name for mom.',
			answer: 'MOTHER',
			row: 1,
			col: 5,
		},
		3: {
			clue: 'How about some hugs and ____.',
			answer: 'KISSES',
			row: 2,
			col: 8,
		},
		4: {
			clue: 'IWe say ____ you for the gifts.',
			answer: 'THANK',
			row: 2,
			col: 12,
		},
		6: {
			clue: 'Mail a ____ to Grandma.',
			answer: 'CARD',
			row: 3,
			col: 10,
		},
	},
};

export default {
	quiz1,
	quiz2,
	mother,
	winter,
};
