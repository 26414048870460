/** @format */

import { useOktaAuth } from '@okta/okta-react';
import { inject, observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import MessageDismissibleBlock from './MessageDismissibleBlock';

const Login = inject('slideStore')(
	observer((props) => {
		const { authState, authService } = useOktaAuth();
		const [userInfo, setUserInfo] = useState(null);

		useEffect(() => {
			if (!authState.isAuthenticated) {
				// When user isn't authenticated, forget any user info
				setUserInfo(null);
				props.slideStore.setUser(null);
				console.log('user is logging out');
			} else {
				authService.getUser().then((info) => {
					setUserInfo(info);
					props.slideStore.setUser(info);
					console.log('user is logging in');
				});
			}
		}, [authState, authService]); // Update if authState changes

		const login = async () => {
			authService.login('/');
		};

		if (authState.isPending) {
			return <div>Loading...</div>;
		}

		return (
			<div>
				<div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
					{!authState.isAuthenticated && (
						<div>
							<MessageDismissibleBlock
								message='Login to see more slides...'
								size='mini'
								color='yellow'
								visible={true}
								autoDis={false}
							/>
						</div>
					)}

					{authState.isAuthenticated && !userInfo && (
						<div>
							<p>Loading user information...</p>
						</div>
					)}

					{authState.isAuthenticated && userInfo && (
						<div>
							<MessageDismissibleBlock
								message='Login is successful. Welcome!'
								size='mini'
								color='green'
								visible={true}
								autoDis={true}
							/>
						</div>
					)}
				</div>
			</div>
		);
	})
);

export default Login;
