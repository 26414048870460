/** @format */

import React from 'react';
import musicStore from '../stores/musicStore';
import collectSlideUtils from './collectSlideUtils';
import _ from 'lodash';
import {
	MUSIC_ARRAY,
	MUSIC_GROUP_MAP,
	MUSIC_DISPLAY_MAP,
	DEFAULT_MUSIC,
	MUSIC_LYRIC,
} from '../constants/musicConfigs';
import lyrics from '../assets/music/lyrics';

const initializeData = (isLoggedIn) => {
	musicStore.setLevel(MUSIC_GROUP_MAP.get(DEFAULT_MUSIC));
	musicStore.setBook(DEFAULT_MUSIC);
	musicStore.setDefaultSelectOptions(
		collectSlideUtils.getDropdownOption([DEFAULT_MUSIC], MUSIC_DISPLAY_MAP)[0]
	);
	musicStore.setDefaultLevelOptions(
		getLevelOption([DEFAULT_MUSIC], MUSIC_GROUP_MAP)[0]
	);

	let levelOptions, dropdownOptions;
	if (isLoggedIn) {
		levelOptions = getLevelOption(MUSIC_ARRAY, MUSIC_GROUP_MAP);
		const slideArrayByLevel = MUSIC_ARRAY.filter(
			(book) =>
				MUSIC_GROUP_MAP.get(book) === musicStore.defaultLevelOption.value
		);
		dropdownOptions = collectSlideUtils.getDropdownOption(
			slideArrayByLevel,
			MUSIC_DISPLAY_MAP
		);
	} else {
		levelOptions = getLevelOption([DEFAULT_MUSIC], MUSIC_GROUP_MAP);
		dropdownOptions = collectSlideUtils.getDropdownOption(
			[DEFAULT_MUSIC],
			MUSIC_DISPLAY_MAP
		);
	}

	musicStore.setLevelOptions(levelOptions);
	musicStore.setSelectOptions(dropdownOptions);

	if (musicStore.book && MUSIC_LYRIC.has(musicStore.book)) {
		musicStore.setLyric(createLyric(musicStore.book));
	}
};

const getLevelOption = (bookArray, groupMap) => {
	const levelArray = _.chain(bookArray)
		.map((b) => groupMap.get(b))
		.uniq()
		.value();
	levelArray.sort();
	const options = [];
	for (let i = 0; i < levelArray.length; i++) {
		options.push({
			key: Math.floor(Math.random() * 100000000),
			text: levelArray[i],
			value: levelArray[i],
		});
	}
	return options;
};

const createLyric = (musicName) => {
	const LyricArray = String(lyrics[musicName]).split('\n');
	let componentList = [];
	LyricArray.forEach((item, index) => {
		let sentence = [];
		item.split(' ').forEach((item, index) => {
			sentence.push(
				<span>
					<a>
						{item}
						{'  '}
					</a>
				</span>
			);
		});
		componentList.push(sentence);
	});
	return componentList;
};

export default {
	initializeData,
	createLyric,
};
