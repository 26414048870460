/** @format */

import React from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import congratulation from '../../assets/images/unicorn.svg';
import { Image } from 'semantic-ui-react';
import { CONGRAT_CONFIG } from '../../constants/constants';

const Congrat = function WordCardView(props) {
	const { width, height } = useWindowSize();

	return (
		props.completed && (
			<div>
				<Confetti
					width={width}
					height={height * CONGRAT_CONFIG.get(props.component).confetti}
					numberOfPieces='300'
					recycle='false'
					run='false'
				/>
				<div
					style={{
						position: 'absolute',
						top: height * CONGRAT_CONFIG.get(props.component).image,
						left: width * 0.15,
						transform: `scaleX(-1)`,
					}}>
					<Image src={congratulation} wrapped size='small' />
				</div>
				<div
					style={{
						position: 'absolute',
						top: height * CONGRAT_CONFIG.get(props.component).image,
						right: width * 0.15,
					}}>
					<Image src={congratulation} wrapped size='small' />
				</div>
			</div>
		)
	);
};

export default Congrat;
