/** @format */

import React, { Component } from 'react';
import SlideShow from './SlideShow';
import { Segment } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import SlideButton from './SlideButton';
import AwButton from './AwButton';
import GalleryButton from './GalleryButton';
import DialogueButton from './DialogueButton';
import MusicButton from './MusicButton';
import CrosswordButton from './games/CrosswordButton';
import CrosswordView from './games/CrosswordView';
import WordCardButton from './WordCardButton';
import WordCardView from './games/WordCardView';
import MatchView from './games/MatchView';
import ImageGameButton from './games/ImageGameButton';
import Congrat from './games/Congrat';
import VideoView from './VideoView';
import LyricDisplay from './music/LyricDisplay';
import RandomImageView from './games/RandomImageView';
import CartoonImageView from './CartoonImageView';
import {
	scrambleFileList,
	scrambleDefaultLevel,
} from '../assets/scrambleList/fileList';
import {
	cartoonFileList,
	cartoonDefaultLevel,
} from '../assets/cartoonList/fileList';
import { sceneFileList, sceneDefaultLevel } from '../assets/sceneList/fileList';
import {
	SLIDE,
	BOOK,
	CROSSWORD,
	DIALOGUE,
	MUSIC,
	WORDCARD,
	MATCH,
	MAZE,
	CROSSWORDIMAGE,
	COUNTING,
	COLOR_THEME,
	CURRENT_THEME,
	DIFFERENCE,
	SCRAMBLE,
	CARTOON,
	SCENE,
	ACORN_WORLD,
} from '../constants/constants';
import {
	SLIDE_VIDEO_MAP,
	SLIDE_MATCH_GAME_MAP,
} from '../constants/classSlideConfigs';
import { TOPIC_VIDEO_MAP } from '../constants/gallerySlideConfigs';
import { DIALOGUE_VIDEO_MAP } from '../constants/dialogueConfigs';
import { MUSIC_VIDEO_MAP } from '../constants/musicConfigs';
import crosswordData from '../assets/crossword/crosswordData';

// @inject('slideStore')
const MainView = inject(
	'slideStore',
	'galleryStore',
	'dialogueStore',
	'musicStore',
	'wordCardStore',
	'awStore'
)(
	observer(
		class MainView extends Component {
			constructor(props) {
				super(props);
				this.color = COLOR_THEME[CURRENT_THEME]['segment'];
				this.state = { completed: false };
			}

			completeHandler = () => {
				this.setState({
					completed: true,
				});
			};

			resetHandler = () => {
				this.setState({
					completed: false,
				});
			};

			render() {
				if (this.props.slideStore.content === SLIDE) {
					return (
						<div>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<div style={{ paddingBottom: '5px' }}>
									<SlideButton />
								</div>
								<SlideShow slideArray={this.props.slideStore.slideArray} />
							</Segment>
							{SLIDE_VIDEO_MAP.has(this.props.slideStore.book) && (
								<Segment
									inverted
									id='segment-major'
									style={{ backgroundColor: this.color }}>
									<VideoView
										book={this.props.slideStore.book}
										videoMap={SLIDE_VIDEO_MAP}
									/>
								</Segment>
							)}
							{SLIDE_MATCH_GAME_MAP.has(this.props.slideStore.book) && (
								<div>
									<Segment inverted style={{ backgroundColor: this.color }}>
										<h3 style={{ paddingBottom: '5px' }}>
											Drag the words to match their images
										</h3>
										<MatchView
											completeHandler={this.completeHandler}
											resetHandler={this.resetHandler}
											completed={this.completed}
											pColor={this.color}
										/>
									</Segment>
									<Congrat completed={this.state.completed} component='class' />
								</div>
							)}
						</div>
					);
				} else if (this.props.slideStore.content === ACORN_WORLD) {
					return (
						<div>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<div style={{ paddingBottom: '5px' }}>
									<AwButton />
								</div>
								<SlideShow slideArray={this.props.awStore.slideArray} />
							</Segment>
						</div>
					);
				} else if (this.props.slideStore.content === BOOK) {
					return (
						<div>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<div style={{ paddingBottom: '10px' }}>
									<GalleryButton />
								</div>
								<SlideShow slideArray={this.props.galleryStore.slideArray} />
							</Segment>
							{TOPIC_VIDEO_MAP.has(this.props.galleryStore.book) && (
								<Segment
									inverted
									id='segment-major'
									style={{ backgroundColor: this.color }}>
									<VideoView
										book={this.props.galleryStore.book}
										videoMap={TOPIC_VIDEO_MAP}
									/>
								</Segment>
							)}
						</div>
					);
				} else if (this.props.slideStore.content === DIALOGUE) {
					return (
						<div>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<div style={{ paddingBottom: '10px' }}>
									<DialogueButton />
								</div>
								<VideoView
									book={this.props.dialogueStore.book}
									videoMap={DIALOGUE_VIDEO_MAP}
								/>
							</Segment>
						</div>
					);
				} else if (this.props.slideStore.content === MUSIC) {
					return (
						<div>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<div style={{ paddingBottom: '10px' }}>
									<MusicButton />
								</div>
								<VideoView
									book={this.props.musicStore.book}
									videoMap={MUSIC_VIDEO_MAP}
								/>
								{this.props.musicStore.lyric && (
									<div style={{ paddingTop: '10px' }}>
										<LyricDisplay />
									</div>
								)}
							</Segment>
						</div>
					);
				} else if (this.props.slideStore.content === CROSSWORD) {
					return (
						<div>
							<Segment inverted color='yellow'>
								<div style={{ paddingBottom: '15px' }}>
									<CrosswordButton />
								</div>
								<CrosswordView
									gameData={crosswordData[this.props.slideStore.crosswordGame]}
								/>
							</Segment>
						</div>
					);
				} else if (this.props.slideStore.content === WORDCARD) {
					return (
						<div>
							<Segment inverted color='olive'>
								<div style={{ paddingBottom: '15px' }}>
									<WordCardButton />
								</div>
								<WordCardView />
							</Segment>
						</div>
					);
				} else if (this.props.slideStore.content === MATCH) {
					return (
						<div>
							<Segment inverted style={{ backgroundColor: this.color }}>
								<div style={{ paddingBottom: '15px' }}>
									<WordCardButton />
								</div>
								<MatchView
									completeHandler={this.completeHandler}
									resetHandler={this.resetHandler}
									completed={this.completed}
									pColor={this.color}
								/>
							</Segment>
							<Congrat completed={this.state.completed} component='match' />
						</div>
					);
				} else if (this.props.slideStore.content === MAZE) {
					return (
						<div>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<div style={{ paddingBottom: '10px' }}>
									<ImageGameButton />
								</div>
								<SlideShow
									slideArray={this.props.slideStore.mazeImageGameArray}
								/>
							</Segment>
						</div>
					);
				} else if (this.props.slideStore.content === CROSSWORDIMAGE) {
					return (
						<div>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<div style={{ paddingBottom: '10px' }}>
									<ImageGameButton />
								</div>
								<SlideShow
									slideArray={this.props.slideStore.crosswordImageGameArray}
								/>
							</Segment>
						</div>
					);
				} else if (this.props.slideStore.content === COUNTING) {
					return (
						<div>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<div style={{ paddingBottom: '10px' }}>
									<ImageGameButton />
								</div>
								<SlideShow
									slideArray={this.props.slideStore.countingImageGameArray}
								/>
							</Segment>
						</div>
					);
				} else if (this.props.slideStore.content === DIFFERENCE) {
					return (
						<div>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<div style={{ paddingBottom: '10px' }}>
									<ImageGameButton />
								</div>
								<SlideShow
									slideArray={this.props.slideStore.differenceImageGameArray}
								/>
							</Segment>
						</div>
					);
				} else if (this.props.slideStore.content === SCRAMBLE) {
					return (
						<div key='scramble'>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<RandomImageView
									fileList={scrambleFileList}
									defaultLevel={scrambleDefaultLevel}
									disable={!this.props.slideStore.user}
									folder={'scramble'}
								/>
							</Segment>
						</div>
					);
				} else if (this.props.slideStore.content === CARTOON) {
					return (
						<div key='cartoon'>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<CartoonImageView
									fileList={cartoonFileList}
									defaultLevel={cartoonDefaultLevel}
									disable={!this.props.slideStore.user}
									folder={'cartoon'}
								/>
							</Segment>
						</div>
					);
				} else if (this.props.slideStore.content === SCENE) {
					return (
						<div key='scene'>
							<Segment
								inverted
								id='segment-major'
								style={{ backgroundColor: this.color }}>
								<RandomImageView
									fileList={sceneFileList}
									defaultLevel={sceneDefaultLevel}
									disable={!this.props.slideStore.user}
									folder={'scene'}
								/>
							</Segment>
						</div>
					);
				}
			}
		}
	)
);

export default MainView;
