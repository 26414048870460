/** @format */

import { observable, makeAutoObservable, reaction } from 'mobx';
import collectWordCardUtils from '../utils/collectWordCardUtils';
import slideStore from './slideStore';

class WordCardStore {
	constructor() {
		makeAutoObservable(this);
	}

	// slide
	@observable selectOptions;
	@observable levelOptions;
	@observable slideArray;
	@observable book;
	@observable level;
	@observable defaultSelectOption;
	@observable defaultLevelOption;

	// wordCard
	@observable imageNameList;
	@observable reset = false;

	setSelectOptions(selectOptions) {
		this.selectOptions = selectOptions;
	}

	setDefaultSelectOptions(defaultSelectOption) {
		this.defaultSelectOption = defaultSelectOption;
	}

	setLevelOptions(levelOptions) {
		this.levelOptions = levelOptions;
	}

	setDefaultLevelOptions(defaultLevelOption) {
		this.defaultLevelOption = defaultLevelOption;
	}

	setSlideArray(slideArray) {
		this.slideArray = slideArray;
	}

	setBook(book) {
		this.book = book;
	}

	setLevel(level) {
		this.level = level;
	}

	// word Card
	setImageNameList(imageNameList) {
		this.imageNameList = imageNameList;
	}

	setReset() {
		this.reset = !this.reset;
	}
}

const wordCardStore = new WordCardStore();

reaction(
	() => Object.values(slideStore.user || ''),
	() => {
		collectWordCardUtils.initializeData(slideStore.isLoggedIn);
	}
);

export default wordCardStore;
